import React from 'react';

import * as types from './TopbarDropdown/DropdownTypes';

import DropDownButton from './TopbarRight/DropDownButton';

const TopbarRight = (props) => {
    let classNames = ['sidebar-right-toggle', 'fas', 'fa-caret-left', 'mx-auto', 'text-white', 'fa-2x'];
    if (props.toggled) {
        classNames.push('toggled');
    }

    return (
        <div className='bl-md item d-none d-lg-flex ml-auto align-items-start'>
            <DropDownButton
                handleTopbarMenuClicked={props.handleTopbarMenuClicked}
                type={types.DROPDOWN_PROFILE}
                active={props.dropdown}
                colour='favourites'
                icon='fas fa-user-circle'
                text='text-dark'
            />
        </div>
    );
};

export default TopbarRight;
