import React, { useState } from 'react';
import { TitleBar, saveStatuses, AsyncSelect } from 'ht-gui';
import { getClasses } from 'ht-resize';
import { useStore } from 'ht-store';

import { Translate } from '@hanssens/ht-translate';
import { verifyProperty, verifyObject } from '../../../../utilities/utility';
import rightsApi from '../../../../api/rightsApi';

import { applicationRules } from './verificationRules';
import { guidRegex } from '../ApplicationCreate';

import Property from '../../../Shared/Property';

let cancelTokenSource = rightsApi()?.CancelToken?.source();

const ApplicationGeneral = (props) => {
    const { application, setApplication, status, error, edit } = props;
    const [tenant] = useStore('rights-tenant');
    const [options, setOptions] = useState([]);

    const onChange = (e, v) => {
        if (!v) {
            setApplication({ ...application, applicationId: v });
        }
        setApplication({ ...application, applicationId: v.value });
    };

    const onInputChange = (inputValue) => {
        if (!inputValue) {
            return;
        }
        setTimeout(() => {
            cancelTokenSource?.cancel('Operation cancelled due to new request.');
            cancelTokenSource = rightsApi().CancelToken.source();
            rightsApi()
                .get(`v1/rights/application/search/${inputValue}`, { cancelToken: cancelTokenSource.token })
                .then((response) => {
                    let o = response.data.map((d) => {
                        return {
                            value: d.id,
                            label: d.name,
                        };
                    });

                    setOptions(o);
                })
                .catch((error) => {
                    if (!rightsApi().isCancel(error)) {
                        console.log('An error has occured during the fetch of the application options', error);
                    }
                });
        }, 400);
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    let id = null;

    if (edit && application.id) {
        id = <Property label={<Translate id='rights.applications.id' />} value={application.id} />;
    }

    let applcationOptions = [...options];
    let applicationValue = null;
    if (application.applicationId) {
        applicationValue = applcationOptions.find((o) => (o.value = application.applicationId));

        if (!applicationValue && application.application) {
            applicationValue = { value: application.applicationId, label: application.application.name };
            applcationOptions = [{ value: application.applicationId, label: application.application.name }, ...applcationOptions];
        }
    }

    return (
        <div className='col-12'>
            <div className='bg-white box-shadow'>
                <TitleBar label={<Translate id='rights.applications.create.general' />} />
                <div className='p-3'>
                    <div className='row'>
                        <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                            {id}

                            <Property label={<Translate id='rights.applications.tenantId' />} value={tenant?.selectedTenant} />

                            <AsyncSelect
                                value={applicationValue}
                                options={applcationOptions}
                                onChange={onChange}
                                onInputChange={onInputChange}
                                label={<Translate id='rights.applications.application' />}
                                id='rights.application.create.application'
                                required
                                error={error && (!verifyProperty(application.applicationId, applicationRules.applicationId) || !guidRegex.test(application.applicationId))}
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                        </div>
                    </div>

                    {/* Error */}
                    {error && !verifyObject(application, applicationRules) ? (
                        <div className='row'>
                            <div className='col-6 '>
                                <div className='alert alert-danger rounded-0 mb-0 mt-3'>
                                    <Translate id='rights.applications.create.error' />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default ApplicationGeneral;
