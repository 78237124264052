import React from 'react';
import { Input, TitleBar, saveStatuses, Switch } from 'ht-gui';
import { getClasses } from 'ht-resize';
import { useStore } from 'ht-store';

import { Translate } from '@hanssens/ht-translate';
import { verifyProperty, verifyObject } from '../../../../utilities/utility';

import { userRules } from './verificationRules';
import { guidRegex } from '../UserCreateAdmin';

import Property from '../../../Shared/Property';

const UserGeneralAdmin = (props) => {
    const { user, setUser, status, error, edit } = props;
    const [tenant] = useStore('rights-tenant');

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    let id = null;

    if (edit && user.id) {
        id = <Property label={<Translate id='rights.users.id' />} value={user.id} />;
    }

    return (
        <div className='col-12'>
            <div className='bg-white box-shadow'>
                <TitleBar label={<Translate id='rights.users.create.general' />} />
                <div className='p-3'>
                    <div className='row'>
                        <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                            {id}

                            <Property label={<Translate id='rights.users.tenantId' />} value={tenant?.selectedTenant} />

                            <Input
                                id='rights.user.create.firstname'
                                value={user.user.firstName}
                                label={<Translate id='rights.users.firstName' />}
                                error={error && !verifyProperty(user.user.firstName, userRules.firstName)}
                                required
                                onChange={(e) => setUser({ ...user, user: { ...user.user, firstName: e.target.value } })}
                                className='w-100 mb-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <Input
                                id='rights.user.create.surName'
                                value={user.user.surName}
                                label={<Translate id='rights.users.surName' />}
                                error={error && !verifyProperty(user.user.surName, userRules.surName)}
                                required
                                onChange={(e) => setUser({ ...user, user: { ...user.user, surName: e.target.value } })}
                                className='w-100 mb-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <Input
                                id='rights.user.create.email'
                                value={user.user.email}
                                label={<Translate id='rights.users.email' />}
                                error={error && !verifyProperty(user.user.email, userRules.email)}
                                required
                                onChange={(e) => setUser({ ...user, user: { ...user.user, email: e.target.value } })}
                                className='w-100 mb-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                            {/* Change to async select after we added an extra api call to fetch the user from the oauth */}
                            <Input
                                id='rights.user.create.userId'
                                value={user.userId}
                                label={<Translate id='rights.users.userId' />}
                                error={error && (!verifyProperty(user.userId, userRules.userId) || !guidRegex.test(user.userId))}
                                required
                                onChange={(e) => setUser({ ...user, userId: e.target.value })}
                                className='w-100 mb-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <Switch
                                label={<Translate id='rights.users.isDefault' />}
                                id='rights.user.create.isDefault'
                                checked={user.isDefault}
                                onChange={(e) => setUser({ ...user, isDefault: e.target.checked })}
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <Switch
                                label={<Translate id='rights.users.isActive' />}
                                id='rights.user.create.isActive'
                                checked={user.isActive}
                                onChange={(e) => setUser({ ...user, isActive: e.target.checked })}
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                        </div>
                    </div>

                    {/* Error */}
                    {error && !verifyObject(user, userRules) ? (
                        <div className='row'>
                            <div className='col-6 '>
                                <div className='alert alert-danger rounded-0 mb-0 mt-3'>
                                    <Translate id='rights.users.create.error' />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default UserGeneralAdmin;
