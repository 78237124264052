import React from 'react';
import { NoContent } from 'ht-gui';

import { Translate } from '@hanssens/ht-translate';

const SelectATenant = ({ className }) => {
    return (
        <div className={className}>
            <NoContent title={<Translate id='rights.selectATenant' />} description={<Translate id='rights.selectATenantDesc' />} icon='fas fa-cloud-exclamation fa-7x' backgroundColour='bg-primary' />
        </div>
    );
};

export default SelectATenant;
