import * as React from 'react';
import { Input, TitleBar, saveStatuses } from 'ht-gui';
import { getClasses } from 'ht-resize';

import { Translate } from '@hanssens/ht-translate';

const UserClaims = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const { user, setUser, status } = props;

    /*********************************************************************/
    /* Event handlers
    /*********************************************************************/
    const handleClaimChanged = (type, value) => {
        let updatedClaims = [...user.claims];

        let index = updatedClaims.findIndex((uc) => uc.type === type);

        if (index === -1) {
            return;
        }

        updatedClaims[index] = {
            ...updatedClaims[index],
            value: value,
        };

        setUser({ ...user, claims: updatedClaims });
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    if (!user.claims || user.claims.length === 0) {
        return null;
    }

    let content = user.claims.map((claim, index) => {
        let classNames = [];

        if (index > 0) {
            classNames.push('w-100 mt-3');
        }

        return (
            <Input
                key={index}
                id={`rights.user.create.${claim.type}`}
                value={claim.value}
                label={<Translate id={`rights.users.claimTypes.${claim.type}`} />}
                onChange={(e) => handleClaimChanged(claim.type, e.target.value)}
                className={classNames.join(' ')}
                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
            />
        );
    });

    return (
        <div className='col-12 mt-3'>
            <div className='bg-white box-shadow'>
                <TitleBar label={<Translate id='rights.users.create.dynamicProperties' />} />
                <div className='p-3'>
                    <div className='row'>
                        <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>{content}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserClaims;
