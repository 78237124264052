import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoginLoadingScreen } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';
import { useStore } from 'ht-store';
import { useSelector } from 'react-redux';

import userManager from '../../../configs/userManager';

import logo from '../../assets/images/htLogo.png';
import background from '../../assets/images/login-background.jpg';

const states = [
    { key: 'loading', label: <Translate id='login.loading' />, icon: 'far fa-spinner-third text-primary' },
    { key: 'loading_user_info', label: <Translate id='login.loadingUserInfo' />, icon: 'far fa-spinner-third text-primary' },
    { key: 'loading_app', label: <Translate id='login.loadingApp' />, icon: 'far fa-spinner-third text-primary' },
];

const PrivateRoute = (props) => {
    /********************************/
    /* State
    /********************************/
    const isLoading = useSelector((state) => state.oidc.isLoadingUser);
    const currentUser = useSelector((state) => state.oidc.user);

    const [auth, setAuth] = useStore('auth');
    const navigate = useNavigate();
    const location = useLocation();

    /********************************/
    /* Init
    /********************************/
    useEffect(() => {
        if (!isLoading && currentUser && !auth.error && !auth.isAuthenticated) {
            if (!currentUser) {
                setAuth({ ...auth, error: 'No access' });
            }

            setAuth({ ...auth, isAuthenticated: true });
        }
    }, [isLoading, currentUser, auth, setAuth]);

    useEffect(() => {
        if (!isLoading && !currentUser) {
            userManager.signinRedirect({
                data: {
                    redirectUrl: location.pathname + location.search,
                },
            });
        }
    }, [isLoading, currentUser, location]);

    useEffect(() => {
        if (auth.error && !auth.isAuthenticated) {
            navigate('/unauthorized', { replace: true });
        }
    }, [auth, navigate]);

    /********************************/
    /* Functions
    /********************************/
    const render = (content) => {
        return content;
    };

    /********************************/
    /* Render
    /********************************/
    // if (!auth.error) {
    if (!isLoading && currentUser && auth.isAuthenticated) {
        let Component = props.component;
        return <Component {...props.props} />;
    }

    let currentState = '';
    if (isLoading && !currentUser && !auth.isAuthenticated) {
        currentState = 'loading';
    } else if (isLoading && currentUser && !auth.isAuthenticated) {
        currentState = 'loading_user_info';
    }

    return render(<LoginLoadingScreen logo={logo} image={background} states={states} title={'Rights'} subTitle={<Translate id='login.subTitle' />} currentState={currentState} />);
    // } else return null;
};

export default PrivateRoute;
