import React from 'react';
import { Input, TitleBar, saveStatuses } from 'ht-gui';
import { getClasses } from 'ht-resize';
import { useStore } from 'ht-store';

import { Translate } from '@hanssens/ht-translate';
import { verifyProperty, verifyObject } from '../../../../utilities/utility';

import { userRules } from './verificationRules';
import { emailRegex } from '../UserCreate';

import Property from '../../../Shared/Property';

const UserGeneral = (props) => {
    const { user, setUser, status, error } = props;
    const [tenant] = useStore('rights-tenant');

    console.log(emailRegex.test(user.email?.toLowerCase()));

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    return (
        <div className='col-12'>
            <div className='bg-white box-shadow'>
                <TitleBar label={<Translate id='rights.users.create.general' />} />
                <div className='p-3'>
                    <div className='row'>
                        <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                            <Property label={<Translate id='rights.users.tenantId' />} value={tenant?.selectedTenant} />

                            <Input
                                id='rights.user.create.firstName'
                                value={user.firstName}
                                label={<Translate id='rights.users.firstName' />}
                                error={error && !verifyProperty(user.firstName, userRules.firstName)}
                                required
                                onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                                className='w-100 mb-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <Input
                                id='rights.user.create.surName'
                                value={user.surName}
                                label={<Translate id='rights.users.surName' />}
                                error={error && !verifyProperty(user.surName, userRules.surName)}
                                required
                                onChange={(e) => setUser({ ...user, surName: e.target.value })}
                                className='w-100 mb-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <Input
                                id='rights.user.create.email'
                                value={user.email}
                                label={<Translate id='rights.users.email' />}
                                error={error && (!verifyProperty(user.email, userRules.email) || !emailRegex.test(user.email?.toLowerCase()))}
                                required
                                onChange={(e) => setUser({ ...user, email: e.target.value })}
                                className='w-100 mb-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                        </div>
                    </div>

                    {/* Error */}
                    {error && !verifyObject(user, userRules) ? (
                        <div className='row'>
                            <div className='col-6 '>
                                <div className='alert alert-danger rounded-0 mb-0 mt-3'>
                                    <Translate id='rights.users.create.error' />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default UserGeneral;
