import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { useTranslate } from '@hanssens/ht-translate';

// Components
import Roles from '../pages/Roles';
import RoleCreate from '../components/General/Roles/RoleCreate';
import RoleEdit from '../components/General/Roles/RoleEdit';
import RoleInfo from '../pages/Roles/RoleInfo';
import RoleRightsEdit from '../pages/Roles/RoleRightsEdit';

// Translations
import roleTranslations from '../translations/general/roles.json';

const RolesRouter = (props) => {
    useTranslate(roleTranslations);

    return (
        <Routes>
            {/* Rights edit */}
            <Route path='/:id/rights/update' element={<RoleRightsEdit />} />

            {/* Info */}
            <Route path='/info/:id' element={<RoleInfo />} />

            {/* Update */}
            <Route path='/update/:id' element={<RoleEdit />} />

            {/* Create */}
            <Route path='/create' element={<RoleCreate />} />

            {/* Overview */}
            <Route path='/' element={<Roles />} />
        </Routes>
    );
};

export default RolesRouter;
