import React from 'react';
import { PageTitle } from 'ht-gui';

import { Translate } from '@hanssens/ht-translate';

import UsersOverview from '../components/General/Users/UsersOverview';

const Users = (props) => {
    return (
        <>
            <section id='rights-users-overview' className='main-scrollable p-4'>
                <PageTitle title={<Translate id='rights.users.title' />} subTitle={<Translate id='rights.users.subtitle' />} />
                <div className=''>
                    <UsersOverview />
                </div>
            </section>
        </>
    );
};

export default Users;
