import React, { useCallback, useEffect, useState } from 'react';
import { TitleBar, ManageTable, NoContent, ManageTableActions } from 'ht-gui';
import { useStore } from 'ht-store';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import Error from '../../Shared/Error';
import Loading from '../../Shared/Loading';
import CheckDisplay from '../../Shared/Managetable/CheckDisplay';
import ManagetableActionWithTippy from '../../Shared/Managetable/ManageTableActionWithTippy';

import rightsApi from '../../../api/rightsApi';
import { Translate } from '@hanssens/ht-translate';

import { routerPrefix } from '../../../routers/MainRouter';

// Modals
import DeleteModal from '../../Shared/DeleteModal';
import PasswordResetModal from '../../Shared/Modals/PasswordResetModal';
import ResendEmailModal from '../../Shared/Modals/ResendEmailModal';

const UsersOverview = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const [tenant] = useStore('rights-tenant');
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [deletePopup, setDeletePopup] = useState(null);
    const [passwordResetPopup, setPasswordResetPopup] = useState(null);
    const [resendEmailPopup, setResendEmailPopup] = useState(null);

    const navigate = useNavigate();

    /*********************************************************************/
    /* Init
    /*********************************************************************/

    const fetchData = useCallback(() => {
        setData(null);
        rightsApi()
            .get('v1/rights/tenantuser?tenantId=' + tenant.selectedTenant)
            .then((response) => {
                let responseData = response.data.map((d) => {
                    return {
                        ...d,
                        name: `${d.user.firstName} ${d.user.surName}`,
                    };
                });

                setError(null);
                setData(responseData);
            })
            .catch((error) => {
                if (error.response) {
                    setError({ statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null });
                } else {
                    setError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                }
                setData(null);
            });
    }, [tenant]);

    useEffect(() => {
        if (tenant && tenant.selectedTenant) {
            fetchData();
        }
    }, [fetchData, tenant]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/

    const handleCreateClicked = (e) => {
        e.preventDefault();
        navigate(routerPrefix + '/users/create');
    };

    const handleEditClicked = (data) => {
        navigate(`${routerPrefix}/users/update/${data.id}`);
    };

    const handleDeleteClicked = (data) => {
        setDeletePopup({ object: data });
    };

    const handleDoubleClicked = (e, data) => {
        e.preventDefault();
        navigate(`${routerPrefix}/users/update/${data.id}`);
    };

    const handleRolesEditClicked = (data) => {
        navigate(`${routerPrefix}/users/roles/${data.id}`);
    };

    const handleConfirmDeleteClicked = (object, onSuccess, onError) => {
        rightsApi()
            .delete(`v1/rights/tenantUser/${object.id}`)
            .then((response) => {
                setData([...data].filter((t) => t.id !== object.id));
                onSuccess();
            })
            .catch((error) => {
                onError();
            });
    };

    const handleTenantDeleted = (object) => {
        setDeletePopup(null);
    };

    const handlePasswordResetClicked = (data) => {
        setPasswordResetPopup({ object: data });
    };

    const handleConfirmPasswordResetClicked = (object, onSuccess, onError) => {
        rightsApi()
            .put(`v1/rights/tenantUser/reset/${object.id}`)
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                onError();
            });
    };

    const handlePasswordReset = (object) => {
        setPasswordResetPopup(null);
    };

    const handleResendEmailClicked = (data) => {
        setResendEmailPopup({ object: data });
    };

    const handleConfirmResendEmailClicked = (object, onSuccess, onError) => {
        rightsApi()
            .put(`v1/rights/tenantUser/resend/${object.id}`)
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                onError();
            });
    };

    const handleEmailResend = () => {
        setResendEmailPopup(null);
    };

    /*********************************************************************/
    /* Managetable config
    /*********************************************************************/

    const componentsAfter = (
        <Button variant='contained' className='bg-success text-white rounded-0 ml-1' onClick={handleCreateClicked}>
            <Translate id='rights.users.createNew' />
        </Button>
    );

    const config = {
        columns: {
            name: {
                label: <Translate id='rights.users.name' />,
            },
            // user: {
            //     label: <Translate id='rights.users.user' />,
            // },
            isDefault: {
                label: <Translate id='rights.users.isDefault' />,
                component: CheckDisplay,
                props: {
                    checked: 'isDefault',
                },
            },
            isActive: {
                label: <Translate id='rights.users.isActive' />,
                component: CheckDisplay,
                props: {
                    checked: 'isActive',
                },
            },
            actions: {
                label: 'Actions',
                component: ManageTableActions,
                props: {
                    actions: [
                        {
                            component: ManagetableActionWithTippy,
                            icon: 'fal fa-pencil',
                            hoverIcon: 'fas fa-pencil',
                            onClick: handleEditClicked,
                            tippyText: <Translate id='rights.users.actions.editUser' />,
                        },
                        {
                            component: ManagetableActionWithTippy,
                            icon: 'fal fa-user-unlock',
                            hoverIcon: 'fas fa-user-unlock',
                            onClick: handleRolesEditClicked,
                            tippyText: <Translate id='rights.users.actions.manageUserRoles' />,
                        },
                        {
                            component: ManagetableActionWithTippy,
                            icon: 'fal fa-lock-alt',
                            hoverIcon: 'fas fa-lock-alt',
                            onClick: handlePasswordResetClicked,
                            tippyText: <Translate id='rights.users.actions.resetPassword' />,
                        },
                        {
                            component: ManagetableActionWithTippy,
                            icon: 'fal fa-envelope',
                            hoverIcon: 'fas fa-envelope',
                            onClick: handleResendEmailClicked,
                            tippyText: <Translate id='rights.users.actions.resendActivationEmail' />,
                        },
                        {
                            component: ManagetableActionWithTippy,
                            icon: 'fal fa-trash',
                            hoverIcon: 'fas fa-trash',
                            onClick: handleDeleteClicked,
                            tippyText: <Translate id='rights.users.actions.deleteUser' />,
                        },
                    ],
                },
            },
        },
        search: {
            componentsAfter: componentsAfter,
        },
        noItemsComponent: (
            // No entries
            <NoContent title={<Translate id='rights.users.noEntries' />} description={<Translate id='rights.users.noEntriesDesc' />} backgroundColour='bg-primary' icon='fas fa-users-slash fa-7x' />
        ),
        onDoubleClick: handleDoubleClicked,
        data: data,
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    // Error
    if (error) {
        return <Error code={error.statusCode} message={error?.data} />;
    }

    // Loading
    if (!data) {
        return <Loading title={<Translate id='rights.users.loading' />} description={<Translate id='rights.users.loadingDesc' />} />;
    }

    let deleteModalContent = null;
    if (deletePopup) {
        let description = (
            <>
                <Translate id='rights.users.delete.areYouSureDelete' />
                <div>
                    {/* <div>{deletePopup.object.id}</div> */}
                    <div>
                        {deletePopup.object.user.firstName} {deletePopup.object.user.surName}
                    </div>
                </div>
            </>
        );
        deleteModalContent = (
            <DeleteModal
                object={deletePopup.object}
                onConfirmClicked={handleConfirmDeleteClicked}
                title={<Translate id='rights.users.delete.title' />}
                description={description}
                failed={<Translate id='rights.users.delete.failed' />}
                titleBarColor='bg-danger'
                confirmClasses={['bg-danger', 'text-white']}
                onClose={() => setDeletePopup(null)}
                onDeleted={handleTenantDeleted}
            />
        );
    }

    let passwordResetModalContent = null;
    if (passwordResetPopup) {
        let description = (
            <>
                <Translate id='rights.users.password.areYouSureReset' />
                <div>
                    {/* <div>{deletePopup.object.id}</div> */}
                    <div>
                        {passwordResetPopup.object.user.firstName} {passwordResetPopup.object.user.surName}
                    </div>
                </div>
            </>
        );

        passwordResetModalContent = (
            <PasswordResetModal
                object={passwordResetPopup.object}
                onConfirmClicked={handleConfirmPasswordResetClicked}
                title={<Translate id='rights.users.password.title' />}
                description={description}
                failed={<Translate id='rights.users.password.failed' />}
                onClose={() => setPasswordResetPopup(null)}
                onConfirmed={handlePasswordReset}
            />
        );
    }

    let resendEmailModalContent = null;
    if (resendEmailPopup) {
        let description = (
            <>
                <Translate id='rights.users.emailPopup.areYouSureResend' />
                <div>
                    {/* <div>{deletePopup.object.id}</div> */}
                    <div>
                        {resendEmailPopup.object.user.firstName} {resendEmailPopup.object.user.surName}
                    </div>
                </div>
            </>
        );

        resendEmailModalContent = (
            <ResendEmailModal
                object={resendEmailPopup.object}
                onConfirmClicked={handleConfirmResendEmailClicked}
                title={<Translate id='rights.users.emailPopup.title' />}
                description={description}
                failed={<Translate id='rights.users.emailPopup.failed' />}
                onClose={() => setResendEmailPopup(null)}
                onConfirmed={handleEmailResend}
            />
        );
    }

    return (
        <>
            {deleteModalContent}
            {passwordResetModalContent}
            {resendEmailModalContent}

            <div className='box-shadow bg-white'>
                <TitleBar label={<Translate id='rights.users.title' />} />
            </div>

            <ManageTable {...config} />
        </>
    );
};

export default UsersOverview;
