import React from 'react';
import { computeTextColor } from '../../../utilities/computeTextColor';

const ColorDisplay = (props) => {
    if (props.hex != null) {
        if (!props.hex.trim().startsWith('#')) {
            props.hex = `#${props.hex.trim()}`;
        }

        return (
            <div className='d-flex'>
                <span className='badge smallshadow' style={{ backgroundColor: props.hex, color: computeTextColor(props.hex) }}>
                    {props.hex}
                </span>
            </div>
        );
    } else {
        return null;
    }
};

export default ColorDisplay;
