import React from 'react';
import { SelectButton } from 'ht-gui';
import { useSelector } from 'react-redux';
import { Translate } from '@hanssens/ht-translate';
import { useParams, useNavigate, useLocation, matchPath } from 'react-router-dom';

import { routerPrefix } from '../../../../routers/MainRouter';

const ButtonBar = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const match = matchPath({ path: '/rights/applications/update/:id/:tab' }, location.pathname);

    const currentUser = useSelector((state) => state.oidc.user);
    const isSuperAdmin = currentUser && currentUser.profile && currentUser.profile.role && currentUser.profile.role.includes('Super admin');

    /*********************************************************************/
    /* Functions
    /*********************************************************************/
    const handleButtonClicked = React.useCallback(
        (tab) => {
            navigate(`${routerPrefix}/applications/update/${id}/${tab}`);
        },
        [id, navigate]
    );

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    let currentId = '';
    if (match && match.params && match.params.tab) {
        currentId = match.params.tab.toLowerCase();
    }

    return (
        <div className='row'>
            {isSuperAdmin ? (
                <SelectButton
                    width='25'
                    label={<Translate id='rights.applications.selectButton.settings' />}
                    icon='fas fa-wrench'
                    id='settings'
                    currentId={currentId}
                    onClick={handleButtonClicked}
                    background='bg-danger'
                    color='text-danger'
                />
            ) : null}
            <SelectButton
                width='25'
                label={<Translate id='rights.applications.selectButton.parts' />}
                icon='fas fa-link'
                id='parts'
                currentId={currentId}
                onClick={handleButtonClicked}
                background='bg-secondary'
                color='text-secondary'
            />
        </div>
    );
};

export default ButtonBar;
