import React from 'react';
import { MultiSelect } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';

const MultiSelectWrapper = ({ value, options, onChange, disabled, data, allowAll }) => {
    if (allowAll) {
        return <Translate id='rights.roles.rights.allParts' />;
    }

    return <MultiSelect value={value} options={options} onChange={(e) => onChange(e, data)} label={<Translate id='rights.roles.rights.parts' />} id='rights-app-parts' disabled={disabled} />;
};

export default MultiSelectWrapper;
