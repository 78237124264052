import * as React from 'react';
import { ManageTable, TitleBar, NoContent } from 'ht-gui';
import { useStore } from 'ht-store';
import { useSelector } from 'react-redux';
import { Translate } from '@hanssens/ht-translate';
import { useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import Tippy from '@tippyjs/react';

import rightsApi from '../../../../api/rightsApi';

import ApplicationEditPartCreate from './ApplicationEditPartCreate';
import Error from '../../../Shared/Error';

const ApplicationEditTenantParts = ({ partId, baseUri, endpoint, applicationId }) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const [auth] = useStore('auth');

    const [tenantAppParts, setTenantAppParts] = React.useState(null);
    const [refreshing, setRefreshing] = React.useState(null);
    const [create, setCreate] = React.useState(false);

    const [apiError, setApiError] = React.useState(false);

    const { id } = useParams();

    const currentUser = useSelector((state) => state.oidc.user);
    const isSuperAdmin = currentUser && currentUser.profile && currentUser.profile.role && currentUser.profile.role.includes('Super admin');

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    React.useEffect(() => {
        setApiError(false);
        rightsApi()
            .get('v1/rights/tenantApplicationPart', { params: { tenantApplicationId: id, partId: partId } })
            .then((response) => {
                setTenantAppParts(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    setApiError({
                        statusCode: error.response.status,
                        data: typeof error?.response?.data === 'string' ? error?.response?.data : null,
                    });
                } else {
                    setApiError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                }
                setTenantAppParts([]);
            });
    }, [partId, id]);

    /*********************************************************************/
    /* Event handlers
    /*********************************************************************/
    const handleCreateClicked = React.useCallback(() => {
        setCreate(true);
    }, []);

    const handleRefreshClicked = React.useCallback(() => {
        if (!baseUri || !endpoint) {
            return;
        }

        setRefreshing(true);

        let url = `${baseUri.replace(/\/+$/, '')}/${endpoint.replace(/^\/+/, '')}`;

        rightsApi()
            .get(url, { params: { tenantApplicationId: id, partId: partId } })
            .then((response) => {
                setTenantAppParts(response.data);
                setRefreshing(false);
            })
            .catch((error) => {
                setRefreshing(false);
            });
    }, [id, partId, baseUri, endpoint]);

    const handlePartAdded = React.useCallback((newPart) => {
        setTenantAppParts((data) => {
            let updatedData = data;
            updatedData.push(newPart);
            return updatedData;
        });
    }, []);

    const checkIfUserHasCorrectRight = React.useCallback(() => {
        switch (applicationId.toLowerCase()) {
            case 'C3C985C0-D47A-4276-B8CB-ADBB09AC5E0C'.toLowerCase():
                return auth && auth.rights && auth.rights.includes('groupMembership.sync.parts');
            default:
                return false;
        }
    }, [auth, applicationId]);

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    let content = null;

    if (tenantAppParts && !create) {
        let componentsAfter = (
            <div className='d-flex flex-row'>
                {isSuperAdmin ? (
                    <Button variant='contained' className='bg-success text-white rounded-0 ml-1' onClick={handleCreateClicked}>
                        <Translate id='rights.applications.part.createNew' />
                    </Button>
                ) : null}
                {isSuperAdmin || (checkIfUserHasCorrectRight() && baseUri && endpoint) ? (
                    <Tippy content={<Translate id='rights.applications.part.refresh' />}>
                        <Button variant='contained' disabled={refreshing} className='bg-secondary text-white rounded-0 ml-1' onClick={handleRefreshClicked}>
                            {refreshing ? <i className='fas fa-spin fa-sync' /> : <i className='fas fa-sync' />}
                        </Button>
                    </Tippy>
                ) : null}
            </div>
        );

        const config = {
            columns: {
                externalId: {
                    label: <Translate id='rights.applications.part.id' />,
                },
                name: {
                    label: <Translate id='rights.applications.part.name' />,
                },
            },
            search: {
                componentsAfter: componentsAfter,
            },
            noItemsComponent: apiError ? (
                <Error code={apiError.statusCode} message={apiError?.data} />
            ) : (
                // No entries
                <NoContent
                    title={<Translate id='rights.applications.part.noEntries' />}
                    description={<Translate id='rights.applications.part.noEntriesDesc' />}
                    backgroundColour='bg-primary'
                    icon='fas fa-shelves-empty fa-7x'
                />
            ),
            data: tenantAppParts,
        };

        content = <ManageTable {...config} />;
    }

    if (create) {
        content = <ApplicationEditPartCreate tenantApplicationId={id} partId={partId} setCreating={setCreate} onPartAdded={handlePartAdded} />;
    }

    return (
        <div className=''>
            <div className='box-shadow'>
                <TitleBar label={<Translate id='rights.applications.parts' />} />
            </div>

            {content}
        </div>
    );
};

export default ApplicationEditTenantParts;
