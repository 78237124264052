import { NOT_EMPTY_STRING, NOT_NULL } from '../../../../utilities/verificationRules';

export const userAdminRules = {
    tenantId: [NOT_NULL, NOT_EMPTY_STRING],
    userId: [NOT_NULL, NOT_EMPTY_STRING],
};

export const userRules = {
    email: [NOT_NULL, NOT_EMPTY_STRING],
    firstName: [NOT_NULL, NOT_EMPTY_STRING],
    surName: [NOT_NULL, NOT_EMPTY_STRING],
};
