import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import { useTranslate } from '@hanssens/ht-translate';

// Components
import Applications from '../pages/Applications';
import ApplicationCreate from '../components/General/Applications/ApplicationCreate';
import ApplicationEdit from '../components/General/Applications/ApplicationEdit';

import ApplicationEditParts from '../components/General/Applications/ApplicationEdit/ApplicationEditParts';
import ApplicationEditSettings from '../components/General/Applications/ApplicationEdit/ApplicationEditSettings';

// Translations
import applicationTranslations from '../translations/general/applications.json';

const ApplicationsRouter = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const currentUser = useSelector((state) => state.oidc.user);
    useTranslate(applicationTranslations);

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    /* Overview */
    let routes = [<Route key='default' path='/' element={<Applications />} />];

    /* Update routes */
    // Rendered with outlet in ApplicationEdit
    let editRoutes = [<Route key='update-index' index element={'Nothing selected'} />, <Route key='update-parts' path='parts' element={<ApplicationEditParts />} />];

    if (currentUser && currentUser.profile && currentUser.profile.role && currentUser.profile.role.includes('Super admin')) {
        /* Add settings update route */
        editRoutes.push(<Route key='update-settings' path='/update/:id/settings' element={<ApplicationEditSettings />} />);
        /* Create */
        routes.push(<Route key='delete' path='/create' element={<ApplicationCreate />} />);
    }

    // Add all the routes under /update/:id
    routes.push(
        <Route key='update' path='/update/:id' element={<ApplicationEdit />}>
            {editRoutes}
        </Route>
    );

    return <Routes>{routes}</Routes>;
};

export default ApplicationsRouter;
