import React from 'react';

// Css
import './TitleBarToggle.scss';

const TitleBarToggle = ({ toggled, onToggle }) => {
    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onToggle(!toggled);
    };

    let icon = <i className='text-white fas fa-caret-right' />;
    if (toggled) {
        icon = <i className='text-white fas fa-caret-down' />;
    }

    return (
        <div className='titleBar-toggle px-1 ml-auto d-flex align-items-center clickable' onClick={handleClick}>
            <div className='d-flex align-items-center'>{icon}</div>
        </div>
    );
};

export default TitleBarToggle;
