import React, { useEffect, useState, useCallback } from 'react';
import { PageTitle, SaveButton, saveStatuses, saveTypes } from 'ht-gui';
import { getClasses } from 'ht-resize';
import { useNavigate, useParams } from 'react-router-dom';
import { Translate } from '@hanssens/ht-translate';
import { useSelector } from 'react-redux';

import rightsApi from '../../../api/rightsApi';

import UserGeneralAdmin from './UserCreate/UserGeneralAdmin';
import UserClaims from './UserCreate/UserClaims';
import Loading from '../../Shared/Loading';
import Error from '../../Shared/Error';

import { verifyObject } from '../../../utilities/verificator';
import { userRules } from './UserCreate/verificationRules';
import { guidRegex } from './UserCreateAdmin';
import { routerPrefix } from '../../../routers/MainRouter';
import UserGeneral from './UserEdit/UserGeneral';

const UserEdit = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const navigate = useNavigate();
    const { id } = useParams();

    const [user, setUser] = useState(null);
    const [status, setStatus] = useState({
        saveStatus: saveStatuses.NEUTRAL,
    });
    const [error, setError] = useState(false);
    const [apiError, setApiError] = useState(null);

    const currentUser = useSelector((state) => state.oidc.user);

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    useEffect(() => {
        rightsApi()
            .get('v1/rights/tenantUser/' + id, { params: { withClaims: true } })
            .then((response) => {
                setUser(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    setApiError({ statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null });
                } else {
                    setApiError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                }
            });
    }, [id]);

    /*********************************************************************/
    /* Helper functions
    /*********************************************************************/
    const updateUser = useCallback(
        (user) => {
            if (currentUser && currentUser.profile && currentUser.profile.role && currentUser.profile.role.includes('Super admin')) {
                rightsApi()
                    .put(`v1/rights/tenantUser/${user.id}/admin`, user)
                    .then((response) => {
                        navigate(`${routerPrefix}/users`);

                        setStatus({
                            ...status,
                            saveStatus: saveStatuses.SAVED,
                        });
                    })
                    .catch((error) => {
                        setStatus({
                            ...status,
                            saveStatus: saveStatuses.ERROR,
                        });
                    });
            } else {
                rightsApi()
                    .put(`v1/rights/tenantUser/${user.id}`, user)
                    .then((response) => {
                        navigate(`${routerPrefix}/users`);

                        setStatus({
                            ...status,
                            saveStatus: saveStatuses.SAVED,
                        });
                    })
                    .catch((error) => {
                        setStatus({
                            ...status,
                            saveStatus: saveStatuses.ERROR,
                        });
                    });
            }
        },
        [currentUser, navigate, status]
    );

    /*********************************************************************/
    /* Save & cancel functions
    /*********************************************************************/

    useEffect(() => {
        if (status.saveStatus === saveStatuses.SAVE_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.SAVING,
            });

            // Verify
            if (!verifyObject(user, userRules)) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }

            // Check if we have valid guids
            if (!guidRegex.test(user.userId)) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }
            updateUser(user);
        }
    }, [status, user, updateUser]);

    useEffect(() => {
        if (status.saveStatus === saveStatuses.CANCEL_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.CANCELING,
            });

            navigate(`${routerPrefix}/users`);
        }
    }, [status, navigate]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/

    const handleSaveStatusChanged = (s) => {
        setStatus({
            ...status,
            saveStatus: s,
        });
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    // Loading
    let content = <Loading title={<Translate id='rights.users.update.loading' />} description={<Translate id='rights.users.update.loadingDesc' />} />;

    // Error
    if (apiError) {
        content = <Error code={apiError.statusCode} message={apiError?.data} />;
    }

    if (user) {
        let userGeneralContent = <UserGeneral user={user} setUser={setUser} status={status} error={error} edit />;
        if (currentUser && currentUser.profile && currentUser.profile.role && currentUser.profile.role.includes('Super admin')) {
            userGeneralContent = <UserGeneralAdmin user={user} setUser={setUser} status={status} error={error} edit />;
        }
        content = (
            <div className='row'>
                {userGeneralContent}
                <UserClaims user={user} setUser={setUser} status={status} error={error} edit />

                <div className='col-12'>
                    <div className='bg-white box-shadow p-3 mt-3'>
                        <div className='row'>
                            <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                                <div className='w-50'>
                                    <SaveButton showFeedback type={saveTypes.CONTROLLED} status={status.saveStatus} setStatus={handleSaveStatusChanged} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <section id='rights-user-update' className='main-scrollable p-4'>
            <PageTitle title={<Translate id='rights.users.update.title' />} subTitle={<Translate id='rights.users.update.subtitle' />} />

            {content}
        </section>
    );
};

export default UserEdit;
