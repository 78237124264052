export const storeConfig = {
    'rights-tenant': {
        initialValue: {
            selectedTenant: null,
        },
        persist: false,
    },

    'rights-roleRights-update': {
        initialValue: {
            tenantApplicationRoleRights: null,
            tenantApplicationPartRoleRights: null,
        },
        persist: false,
    },
};
