import React, { useCallback, useEffect, useState } from 'react';
import { TitleBar, ManageTable, NoContent, ManageTableActions } from 'ht-gui';
import { useStore } from 'ht-store';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import Error from '../../Shared/Error';
import Loading from '../../Shared/Loading';
import DeleteModal from '../../Shared/DeleteModal';
import CheckDisplay from '../../Shared/Managetable/CheckDisplay';
import ColorDisplay from '../../Shared/Managetable/ColorDisplay';
import ManagetableActionWithTippy from '../../Shared/Managetable/ManageTableActionWithTippy';

import rightsApi from '../../../api/rightsApi';
import { Translate } from '@hanssens/ht-translate';

import { routerPrefix } from '../../../routers/MainRouter';

const RolesOverview = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const [tenant] = useStore('rights-tenant');
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [deletePopup, setDeletePopup] = useState(null);

    const navigate = useNavigate();

    /*********************************************************************/
    /* Init
    /*********************************************************************/

    const fetchData = useCallback(() => {
        setData(null);
        rightsApi()
            .get('v1/rights/tenantRole?tenantId=' + tenant.selectedTenant)
            .then((response) => {
                setError(null);
                setData(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    setError({ statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null });
                } else {
                    setError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                }
                setData(null);
            });
    }, [tenant]);

    useEffect(() => {
        if (tenant && tenant.selectedTenant) {
            fetchData();
        }
    }, [fetchData, tenant]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/

    const handleCreateClicked = (e) => {
        e.preventDefault();
        navigate(routerPrefix + '/roles/create');
    };

    const handleEditClicked = (data) => {
        navigate(`${routerPrefix}/roles/update/${data.id}`);
    };

    const handleDeleteClicked = (data) => {
        setDeletePopup({ object: data });
    };

    const handleDoubleClicked = (e, data) => {
        e.preventDefault();
        navigate(`${routerPrefix}/roles/info/${data.id}`);
    };

    const handleInfoClicked = (data) => {
        navigate(`${routerPrefix}/roles/info/${data.id}`);
    };

    const handleRightsEditClicked = (data) => {
        navigate(`${routerPrefix}/roles/${data.id}/rights/update`);
    };

    const handleConfirmDeleteClicked = (object, onSuccess, onError) => {
        rightsApi()
            .delete(`v1/rights/tenantRole/${object.id}`)
            .then((response) => {
                let currentUsers = [...data];
                let index = currentUsers.findIndex((t) => t.id === object.id);
                if (index === -1) {
                    return;
                }
                currentUsers.splice(index, 1);
                setData(currentUsers);
                onSuccess();
            })
            .catch((error) => {
                onError();
            });
    };

    const handleTenantDeleted = (object) => {
        setDeletePopup(null);
    };

    /*********************************************************************/
    /* Managetable config
    /*********************************************************************/

    const componentsAfter = (
        <Button variant='contained' className='bg-success text-white rounded-0 ml-1' onClick={handleCreateClicked}>
            <Translate id='rights.roles.createNew' />
        </Button>
    );

    const config = {
        columns: {
            name: {
                label: <Translate id='rights.roles.name' />,
            },
            color: {
                label: <Translate id='rights.roles.color' />,
                component: ColorDisplay,
                props: {
                    hex: 'color',
                },
            },
            isActive: {
                label: <Translate id='rights.roles.isActive' />,
                component: CheckDisplay,
                props: {
                    checked: 'isActive',
                },
            },
            actions: {
                label: 'Actions',
                component: ManageTableActions,
                props: {
                    actions: [
                        {
                            component: ManagetableActionWithTippy,
                            icon: 'fal fa-circle-info',
                            hoverIcon: 'fas fa-circle-info',
                            onClick: handleInfoClicked,
                            tippyText: <Translate id='rights.roles.actions.info' />,
                        },
                        {
                            component: ManagetableActionWithTippy,
                            icon: 'fal fa-pencil',
                            hoverIcon: 'fas fa-pencil',
                            onClick: handleEditClicked,
                            tippyText: <Translate id='rights.roles.actions.edit' />,
                        },
                        {
                            component: ManagetableActionWithTippy,
                            icon: 'fal fa-key',
                            hoverIcon: 'fas fa-key',
                            onClick: handleRightsEditClicked,
                            tippyText: <Translate id='rights.roles.actions.manageRights' />,
                        },

                        {
                            component: ManagetableActionWithTippy,
                            icon: 'fal fa-trash',
                            hoverIcon: 'fas fa-trash',
                            onClick: handleDeleteClicked,
                            tippyText: <Translate id='rights.roles.actions.delete' />,
                        },
                    ],
                },
            },
        },
        search: {
            componentsAfter: componentsAfter,
        },
        onDoubleClick: handleDoubleClicked,
        noItemsComponent: (
            // No entries
            <NoContent title={<Translate id='rights.roles.noEntries' />} description={<Translate id='rights.roles.noEntriesDesc' />} backgroundColour='bg-primary' icon='fas fa-shelves-empty fa-7x' />
        ),
        data: data,
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    // Error
    if (error) {
        return <Error code={error.statusCode} message={error?.data} />;
    }

    // Loading
    if (!data) {
        return <Loading title={<Translate id='rights.roles.loading' />} description={<Translate id='rights.roles.loadingDesc' />} />;
    }

    let deleteModalContent = null;
    if (deletePopup) {
        let description = (
            <>
                <Translate id='rights.roles.delete.areYouSureDelete' />
                <div>
                    <div>
                        {deletePopup.object.id} - {deletePopup.object.name}
                    </div>
                </div>
            </>
        );
        deleteModalContent = (
            <DeleteModal
                object={deletePopup.object}
                onConfirmClicked={handleConfirmDeleteClicked}
                title={<Translate id='rights.roles.delete.title' />}
                description={description}
                failed={<Translate id='rights.roles.delete.failed' />}
                titleBarColor='bg-danger'
                confirmClasses={['bg-danger', 'text-white']}
                onClose={() => setDeletePopup(null)}
                onDeleted={handleTenantDeleted}
            />
        );
    }

    return (
        <>
            {deleteModalContent}

            <div className='box-shadow bg-white'>
                <TitleBar label={<Translate id='rights.roles.title' />} />
            </div>

            <ManageTable {...config} />
        </>
    );
};

export default RolesOverview;
