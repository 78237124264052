import { createAxiosInstance as createRightsApi } from '../api/rightsApi';

// Default configuration
const defaultConfiguration = {
    // Base props
    API_URI: process.env.REACT_APP_RIGHTS_BASE_URI,
    OIDC_BASE_URI: process.env.REACT_APP_OIDC_BASE_URI,
    OIDC_CLIENT_ID: process.env.REACT_APP_OIDC_CLIENTID,
    HT_TENANTID: process.env.REACT_APP_HT_TENANTID,

    SELECTED_HT_TENANTID: '',
};

// Actual configuration
let config = { ...defaultConfiguration };

// Get the configuration
export const getConfig = () => {
    return { ...config };
};

// Set the configuration
// this must be done at the index.js
export const initialiseConfig = (newConfig) => {
    // Update config
    config = {
        ...defaultConfiguration,
        ...newConfig,
    };

    // Change the base url with the new updated config
    createRightsApi(config);
};

export const tenantIdChanged = (tenantId) => {
    // Update config
    config = {
        ...config,
        SELECTED_HT_TENANTID: tenantId,
    };

    // Change the api with the new updated config
    createRightsApi(config);
};
