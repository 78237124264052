import React, { useEffect, useCallback, useState } from 'react';
import { PageTitle, saveStatuses } from 'ht-gui';
import { useNavigate, useParams } from 'react-router-dom';
import { Translate } from '@hanssens/ht-translate';
import { useStore } from 'ht-store';

import rightsApi from '../../api/rightsApi';
import RoleRightsHeader from '../../components/General/Roles/RoleRights/RoleRightsHeader';

// Components
import Loading from '../../components/Shared/Loading';
import Error from '../../components/Shared/Error';
import RoleApplicationRights from '../../components/General/Roles/RoleRights/RoleApplicationRights';
import RoleApplicationPartRights from '../../components/General/Roles/RoleRights/RoleApplicationPartRights';

import { routerPrefix } from '../../routers/MainRouter';

const RoleRightsEdit = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const { id } = useParams();
    const navigate = useNavigate();
    const [tenant] = useStore('rights-tenant');
    const [roleRights, setRoleRights] = useStore('rights-roleRights-update');

    const [data, setData] = useState(null);
    const [selectedApplication, setSelectedApplication] = useState(null);

    const [error, setError] = useState(null);
    const [status, setStatus] = useState({
        saveStatus: saveStatuses.NEUTRAL,
    });

    // Lookup lists for rights
    const [applicationRights, setApplicationRights] = useState(null);

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    const fetchData = useCallback(() => {
        setData(null);
        setError(null);
        rightsApi()
            .get(`v1/rights/tenantRole/${id}`)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    setError({ statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null });
                } else {
                    setError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                }
                setData(null);
            });
    }, [id]);

    const fetchRights = useCallback(() => {
        setError(null);
        rightsApi()
            .get(`v1/rights/applicationRight/tenant/${tenant.selectedTenant}`)
            .then((response) => {
                setApplicationRights(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    setError({ statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null });
                } else {
                    setError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                }
                setApplicationRights(null);
            });
    }, [tenant]);

    const fetchRoleRights = useCallback(() => {
        setError(null);
        rightsApi()
            .get(`v1/rights/tenantRole/${id}/rights`)
            .then((response) => {
                setRoleRights(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    setError({ statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null });
                } else {
                    setError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                }
                setRoleRights(null);
            });
    }, [id, setRoleRights]);

    useEffect(() => {
        fetchData();
    }, [fetchData, tenant]);

    useEffect(() => {
        fetchRights();
    }, [fetchRights]);

    useEffect(() => {
        fetchRoleRights();
    }, [fetchRoleRights, tenant]);

    /*********************************************************************/
    /* Save & cancel functions
    /*********************************************************************/
    useEffect(() => {
        if (status.saveStatus === saveStatuses.SAVE_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.SAVING,
            });

            rightsApi()
                .put(`v1/rights/tenantRole/${id}/rights`, roleRights)
                .then((response) => {
                    navigate(`${routerPrefix}/roles`);

                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.SAVED,
                    });
                })
                .catch((error) => {
                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.ERROR,
                    });
                });
        }
    }, [status, navigate, roleRights, id]);

    useEffect(() => {
        if (status.saveStatus === saveStatuses.CANCEL_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.CANCELING,
            });

            navigate(`${routerPrefix}/roles`);
        }
    }, [status, navigate]);

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    let content = null;

    if (error) {
        content = <Error code={error.statusCode} message={error?.data} />;
    } else if (!data) {
        content = <Loading title={<Translate id='rights.roles.info.loading' />} description={<Translate id='rights.roles.info.loadingDesc' />} />;
    } else {
        content = (
            <>
                <RoleRightsHeader selectedApplication={selectedApplication} setSelectedApplication={setSelectedApplication} status={status} setStatus={setStatus} />
                <RoleApplicationRights rights={applicationRights} selectedApplication={selectedApplication} />
                <RoleApplicationPartRights rights={applicationRights} selectedApplication={selectedApplication} />
            </>
        );
    }

    return (
        <section id='rights-roles-rights-update' className='main-scrollable p-4'>
            <PageTitle
                title={
                    <>
                        <Translate id='rights.roles.rights.title' /> {data ? `- ${data.name}` : null}
                    </>
                }
                subTitle={<Translate id='rights.roles.rights.subtitle' />}
            />
            {content}
        </section>
    );
};

export default RoleRightsEdit;
