import React from 'react';
import { computeTextColor } from '../../../../utilities/computeTextColor';

const RoleDisplay = ({ color, label, className }) => {
    let classNames = ['badge'];

    if (className) {
        classNames.push(className);
    }

    return (
        <div className={classNames.join(' ')} style={{ color: computeTextColor(color), backgroundColor: color }}>
            {label}
        </div>
    );
};

export default RoleDisplay;
