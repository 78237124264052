import React, { useCallback, useEffect, useState } from 'react';
import { TitleBar, ManageTable, NoContent, ManageTableActions } from 'ht-gui';
import { useStore } from 'ht-store';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Error from '../../Shared/Error';
import Loading from '../../Shared/Loading';

import rightsApi from '../../../api/rightsApi';
import { Translate } from '@hanssens/ht-translate';

import { routerPrefix } from '../../../routers/MainRouter';
import DeleteModal from '../../Shared/DeleteModal';

const ApplicationsOverview = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const currentUser = useSelector((state) => state.oidc.user);

    const [tenant] = useStore('rights-tenant');
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [deletePopup, setDeletePopup] = useState(null);

    const navigate = useNavigate();

    /*********************************************************************/
    /* Init
    /*********************************************************************/

    const fetchData = useCallback(() => {
        setData(null);
        rightsApi()
            .get('v1/rights/tenantApplication?tenantId=' + tenant.selectedTenant)
            .then((response) => {
                setError(null);
                setData(
                    response.data.map((d) => {
                        return {
                            ...d,
                            application: d.application?.name,
                        };
                    })
                );
            })
            .catch((error) => {
                if (error.response) {
                    setError({
                        statusCode: error.response.status,
                        data: typeof error?.response?.data === 'string' ? error?.response?.data : null,
                    });
                } else {
                    setError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                }
                setData(null);
            });
    }, [tenant]);

    useEffect(() => {
        if (tenant && tenant.selectedTenant) {
            fetchData();
        }
    }, [fetchData, tenant]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/

    const handleCreateClicked = (e) => {
        e.preventDefault();
        navigate(routerPrefix + '/applications/create');
    };

    const handleEditClicked = (data) => {
        if (currentUser && currentUser.profile && currentUser.profile.role && currentUser.profile.role.includes('Super admin')) {
            navigate(`${routerPrefix}/applications/update/${data.id}/settings`);
        } else {
            navigate(`${routerPrefix}/applications/update/${data.id}/parts`);
        }
    };

    const handleDeleteClicked = (data) => {
        setDeletePopup({ object: data });
    };

    const handleDoubleClicked = (e, data) => {
        e.preventDefault();
        navigate(`${routerPrefix}/applications/update/${data.id}/settings`);
    };

    const handleConfirmDeleteClicked = (object, onSuccess, onError) => {
        rightsApi()
            .delete(`v1/rights/tenantApplication/${object.id}`)
            .then((response) => {
                let currentUsers = [...data];
                let index = currentUsers.findIndex((t) => t.id === object.id);
                if (index === -1) {
                    return;
                }
                currentUsers.splice(index, 1);
                setData(currentUsers);
                onSuccess();
            })
            .catch((error) => {
                onError();
            });
    };

    const handleTenantDeleted = (object) => {
        setDeletePopup(null);
    };

    /*********************************************************************/
    /* Managetable config
    /*********************************************************************/

    let config = {
        columns: {
            application: {
                label: <Translate id='rights.applications.application' />,
            },
        },
        noItemsComponent: (
            // No entries
            <NoContent
                title={<Translate id='rights.applications.noEntries' />}
                description={<Translate id='rights.applications.noEntriesDesc' />}
                backgroundColour='bg-primary'
                icon='fas fa-display-slash fa-7x'
            />
        ),
        data: data,
    };

    if (currentUser && currentUser.profile && currentUser.profile.role && currentUser.profile.role.includes('Super admin')) {
        const componentsAfter = (
            <Button variant='contained' className='bg-success text-white rounded-0 ml-1' onClick={handleCreateClicked}>
                <Translate id='rights.applications.createNew' />
            </Button>
        );

        config = {
            ...config,
            columns: {
                ...config.columns,
                actions: {
                    label: 'Actions',
                    component: ManageTableActions,
                    props: {
                        actions: [
                            {
                                icon: 'fal fa-pencil',
                                hoverIcon: 'fas fa-pencil',
                                onClick: handleEditClicked,
                            },
                            {
                                icon: 'fal fa-trash',
                                hoverIcon: 'fas fa-trash',
                                onClick: handleDeleteClicked,
                            },
                        ],
                    },
                },
            },
            search: {
                componentsAfter: componentsAfter,
            },
            onDoubleClick: handleDoubleClicked,
        };
    } else {
        config = {
            ...config,
            columns: {
                ...config.columns,
                actions: {
                    label: 'Actions',
                    component: ManageTableActions,
                    props: {
                        actions: [
                            {
                                icon: 'fal fa-pencil',
                                hoverIcon: 'fas fa-pencil',
                                onClick: handleEditClicked,
                            },
                        ],
                    },
                },
            },
        };
    }

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    // Error
    if (error) {
        return <Error code={error.statusCode} message={error?.data} />;
    }

    // Loading
    if (!data) {
        return <Loading title={<Translate id='rights.applications.loading' />} description={<Translate id='rights.applications.loadingDesc' />} />;
    }

    let deleteModalContent = null;
    if (deletePopup) {
        let description = (
            <>
                <Translate id='rights.applications.delete.areYouSureDelete' />
                <div>
                    <div>{deletePopup.object.id}</div>
                    <div>{deletePopup.object.application}</div>
                </div>
            </>
        );
        deleteModalContent = (
            <DeleteModal
                object={deletePopup.object}
                onConfirmClicked={handleConfirmDeleteClicked}
                title={<Translate id='rights.applications.delete.title' />}
                description={description}
                failed={<Translate id='rights.applications.delete.failed' />}
                titleBarColor='bg-danger'
                confirmClasses={['bg-danger', 'text-white']}
                onClose={() => setDeletePopup(null)}
                onDeleted={handleTenantDeleted}
            />
        );
    }

    return (
        <>
            {deleteModalContent}

            <div className='box-shadow bg-white'>
                <TitleBar label={<Translate id='rights.applications.title' />} />
            </div>

            <ManageTable {...config} />
        </>
    );
};

export default ApplicationsOverview;
