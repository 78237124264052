import * as React from 'react';
import { SaveButton, saveStatuses, saveTypes } from 'ht-gui';
import { getClasses } from 'ht-resize';
import { useOutletContext } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import ApplicationGeneral from '../ApplicationCreate/ApplicationGeneral';
import ApplicationSettings from '../ApplicationCreate/ApplicationSettings';

import rightsApi from '../../../../api/rightsApi';
import { routerPrefix } from '../../../../routers/MainRouter';

import { guidRegex } from '../ApplicationCreate';
import { verifyObject } from '../../../../utilities/verificator';
import { applicationRules } from '../ApplicationCreate/verificationRules';

import { checkIfValueIsValidIPv4WithPort, checkIfValueIsValidURI } from '../../../../utilities/regex';

const ApplicationEditSettings = () => {
    const navigate = useNavigate();

    const [application, setApplication] = useOutletContext();
    const [status, setStatus] = React.useState({
        saveStatus: saveStatuses.NEUTRAL,
    });
    const [error, setError] = React.useState(false);

    /*********************************************************************/
    /* Helper functions
    /*********************************************************************/
    const verifyDynamicVariables = (application) => {
        if (application.tenantApplicationSettings) {
            let test = false;

            test = application.tenantApplicationSettings.every((setting) => {
                return !(setting.applicationSetting && setting.applicationSetting.required && !setting.value);
            });
            return test;
        }
        return true;
    };

    /*********************************************************************/
    /* Save & cancel functions
    /*********************************************************************/
    React.useEffect(() => {
        if (status.saveStatus === saveStatuses.SAVE_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.SAVING,
            });

            // Verify
            if (!verifyObject(application, applicationRules)) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }

            // Check if we have a valid baseUri if there is one
            let baseUri = application.apiBaseURI?.trim();
            if (baseUri && !(checkIfValueIsValidIPv4WithPort(baseUri) || checkIfValueIsValidURI(baseUri))) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }

            // Check if we have valid guids
            if (!guidRegex.test(application.applicationId)) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }

            if (!verifyDynamicVariables(application)) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }

            rightsApi()
                .put(`v1/rights/tenantApplication/${application.id}`, { ...application, apiBaseURI: baseUri })
                .then((response) => {
                    navigate(`${routerPrefix}/applications`);

                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.SAVED,
                    });
                })
                .catch((error) => {
                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.ERROR,
                    });
                });
        }
    }, [status, navigate, application]);

    React.useEffect(() => {
        if (status.saveStatus === saveStatuses.CANCEL_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.CANCELING,
            });

            navigate(`${routerPrefix}/applications`);
        }
    }, [status, navigate]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/

    const handleSaveStatusChanged = (s) => {
        setStatus({
            ...status,
            saveStatus: s,
        });
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    return (
        <>
            <ApplicationGeneral application={application} setApplication={setApplication} status={status} error={error} edit />
            <ApplicationSettings className='mt-3' application={application} setApplication={setApplication} status={status} error={error} />

            <div className='col-12'>
                <div className='bg-white box-shadow p-3 mt-3'>
                    <div className='row'>
                        <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                            <div className='w-50'>
                                <SaveButton showFeedback type={saveTypes.CONTROLLED} status={status.saveStatus} setStatus={handleSaveStatusChanged} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ApplicationEditSettings;
