import * as React from 'react';
import { Translate } from '@hanssens/ht-translate';
import { TitleBar, Select } from 'ht-gui';
import { useOutletContext } from 'react-router-dom';

import rightsApi from '../../../../api/rightsApi';

import ApplicationEditTenantParts from './ApplicationEditTenantParts';

const ApplicationEditParts = () => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const [parts, setParts] = React.useState(null);
    const [selectedPart, setSelectedPart] = React.useState(null);

    const [apiError, setApiError] = React.useState(null);

    const [application] = useOutletContext();

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    React.useEffect(() => {
        if (application && application.applicationId && !parts) {
            rightsApi()
                .get('v1/rights/applicationpart/application/' + application.applicationId)
                .then((response) => {
                    setParts(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        setApiError({
                            statusCode: error.response.status,
                            data: typeof error?.response?.data === 'string' ? error?.response?.data : null,
                        });
                    } else {
                        setApiError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                    }
                });
        }
    }, [application, parts]);

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    let partOptions = [];

    if (parts) {
        partOptions = parts.map((p) => {
            return {
                value: p.id,
                label: p.name,
            };
        });
    }

    let selectedPartContent = null;
    if (selectedPart) {
        selectedPartContent = (
            <div className='mt-3'>
                <ApplicationEditTenantParts
                    partId={selectedPart}
                    baseUri={application.apiBaseURI}
                    endpoint={parts.find((p) => p.id === selectedPart)?.syncURI}
                    applicationId={application.applicationId}
                />
            </div>
        );
    }

    return (
        <div className='col-12'>
            <div className='bg-white box-shadow'>
                <TitleBar label={<Translate id='rights.applications.parts' />} />
                <div className='p-3'>
                    <Select
                        value={selectedPart}
                        options={partOptions}
                        onChange={(e) => setSelectedPart(e.target.value)}
                        label={<Translate id='rights.applications.partType' />}
                        id='rights.applications.part'
                        className='w-100'
                        disabled={!parts || parts.length === 0}
                        error={apiError}
                        errorMessage={<Translate id='rights.application.part.error' />}
                    />
                </div>
            </div>

            {selectedPartContent}
        </div>
    );
};

export default ApplicationEditParts;
