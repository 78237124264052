import React from 'react';
import { Input, TitleBar, saveStatuses, Switch, ColourPicker as ColorPicker } from 'ht-gui';
import { getClasses } from 'ht-resize';
import { useStore } from 'ht-store';

import { Translate } from '@hanssens/ht-translate';
import { verifyProperty, verifyObject } from '../../../../utilities/utility';

import { roleRules } from './verificationRules';

import Property from '../../../Shared/Property';

const RoleGeneral = (props) => {
    const { role, setRole, status, error, edit } = props;
    const [tenant] = useStore('rights-tenant');

    /*********************************************************************/
    /* Event handlers
    /*********************************************************************/

    /**
     * Handle when the toggle to enable/disable the externale role name input is changed
     * @param {*} e
     */
    const handleIsExternalRoleNameSet = (e) => {
        // When the boolean isn't checked the external role name should be reset to null
        if (!e.target.checked) {
            setRole({ ...role, externalRoleName: null });
        } else {
            setRole({ ...role, externalRoleName: '' });
        }
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    let id = null;

    if (edit && role.id) {
        id = <Property label={<Translate id='rights.roles.id' />} value={role.id} />;
    }

    const isExternalRoleNameSet = role.externalRoleName != null;

    let externalRoleNameInput = null;

    if (isExternalRoleNameSet) {
        externalRoleNameInput = (
            <Input
                id='rights.role.create.externalRoleName'
                value={role.externalRoleName}
                label={<Translate id='rights.roles.externalRoleName' />}
                error={error && !verifyProperty(role.externalRoleName, roleRules.externalRoleName)}
                onChange={(e) => setRole({ ...role, externalRoleName: e.target.value })}
                className='w-100 mb-3'
                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
            />
        );
    }

    return (
        <div className='col-12'>
            <div className='bg-white box-shadow'>
                <TitleBar label={<Translate id='rights.roles.create.general' />} />
                <div className='p-3'>
                    <div className='row'>
                        <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                            {id}

                            <Property label={<Translate id='rights.roles.tenantId' />} value={tenant?.selectedTenant} />

                            <Input
                                id='rights.role.create.name'
                                value={role.name}
                                label={<Translate id='rights.roles.name' />}
                                error={error && !verifyProperty(role.name, roleRules.name)}
                                required
                                onChange={(e) => setRole({ ...role, name: e.target.value })}
                                className='w-100 mb-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <Switch
                                label={<Translate id='rights.roles.isExternalRoleNameSet' />}
                                id='rights.role.create.isExternalRoleNameSet'
                                checked={isExternalRoleNameSet}
                                onChange={(e) => handleIsExternalRoleNameSet(e)}
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            {externalRoleNameInput}

                            <ColorPicker
                                label={<Translate id='rights.roles.color' />}
                                className='w-100 mb-3'
                                value={role.color}
                                onChange={(v) => setRole({ ...role, color: v })}
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <Switch
                                label={<Translate id='rights.roles.isActive' />}
                                id='rights.role.create.isActive'
                                checked={role.isActive}
                                onChange={(e) => setRole({ ...role, isActive: e.target.checked })}
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                        </div>
                    </div>

                    {/* Error */}
                    {error && !verifyObject(role, roleRules) ? (
                        <div className='row'>
                            <div className='col-6 '>
                                <div className='alert alert-danger rounded-0 mb-0 mt-3'>
                                    <Translate id='rights.roles.create.error' />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default RoleGeneral;
