export const checkIfValueIsValidIPv4 = (value) => {
    return /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(value);
};

export const checkIfValueIsValidPort = (value) => {
    if (!value) {
        return false;
    }

    let bool = false;
    if (!isNaN(value)) {
        if (value >= 0 && value <= 65536) {
            bool = true;
        }
    }
    return bool;
};

export const checkIfValueIsValidIPv4WithPort = (value) => {
    if (!value) {
        return false;
    }

    let splitted = value.split(':');
    let bool = false;
    if (splitted.length === 2) {
        bool = checkIfValueIsValidIPv4(splitted[0]) && checkIfValueIsValidPort(splitted[1]);
    } else if (splitted.length === 1) {
        bool = checkIfValueIsValidIPv4(splitted[0]);
    }
    return bool;
};

export const checkIfValueIsValidURI = (value) => {
    if (!value) {
        return false;
    }

    try {
        new URL(value);
        return true;
    } catch (err) {
        return false;
    }
};
