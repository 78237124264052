import React, { useEffect, useState, useCallback } from 'react';
import { ManageTable, NoContent, sortOrder } from 'ht-gui';
import { useParams } from 'react-router-dom';
import { useStore } from 'ht-store';

import { Translate } from '@hanssens/ht-translate';
import rightsApi from '../../../../api/rightsApi';

import CheckDisplay from '../../../Shared/Managetable/CheckDisplay';
import SwitchWrapper from './SwitchWrapper';
import RoleDisplay from './RoleDisplay';

import Error from '../../../Shared/Error';
import Loading from '../../../Shared/Loading';

const UserRolesOverview = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const { id } = useParams();

    const [tenant] = useStore('rights-tenant');
    const [, setSnackbar] = useStore('snackbarHandler-snackbar');

    const [user, setUser] = useState(null);
    const [tenantRoles, setTenantRoles] = useState(null);

    const [error, setError] = useState({
        user: null,
        tenantRoles: null,
    });

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    const fetchUser = useCallback(() => {
        setUser(null);
        setError((err) => {
            return {
                ...err,
                user: null,
            };
        });
        rightsApi()
            .get(`v1/rights/tenantUser/${id}/withRoles`)
            .then((response) => {
                setUser(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    setError({ ...error, user: { statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null } });
                } else {
                    setError({ ...error, user: { statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' } });
                }
                setUser(null);
            });
    }, [id]);

    const fetchTenantRoles = useCallback(() => {
        if (tenant && tenant.selectedTenant) {
            setTenantRoles(null);
            setError((err) => {
                return {
                    ...err,
                    tenantRoles: null,
                };
            });
            rightsApi()
                .get(`v1/rights/tenantRole/?tenantId=${tenant.selectedTenant}`)
                .then((response) => {
                    setTenantRoles(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        setError({ ...error, tenantRoles: { statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null } });
                    } else {
                        setError({ ...error, tenantRoles: { statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' } });
                    }
                    setTenantRoles(null);
                });
        }
    }, [tenant]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser, tenant]);

    useEffect(() => {
        fetchTenantRoles();
    }, [fetchTenantRoles]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/
    const handleSwitchChanged = (e, data) => {
        if (e.target.checked) {
            rightsApi()
                .put(`v1/rights/tenantuserrole/add?userId=${id}&tenantRoleId=${data.id}`)
                .then((response) => {
                    let tur = {
                        ...response.data,
                        tenantRole: tenantRoles.find((tr) => tr.id === response.data.tenantRoleId),
                    };

                    setUser({ ...user, tenantUserRoles: [...user.tenantUserRoles, tur] });

                    setSnackbar({
                        open: true,
                        text: (
                            <>
                                {data.name} <Translate id='rights.users.roles.snackbar.successAdd' />
                            </>
                        ),
                        severity: 'success',
                        translate: false,
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        setSnackbar({
                            open: true,
                            text: (
                                <>
                                    <Translate id='rights.users.roles.snackbar.error' />{' '}
                                    {typeof error?.response?.data === 'string' ? `${error?.response?.data} (${error.response.status})` : error.response.status}
                                </>
                            ),
                            severity: 'error',
                            translate: false,
                        });
                    } else {
                        setSnackbar({
                            open: true,
                            text: (
                                <>
                                    <Translate id='rights.users.roles.snackbar.error' /> Your dail-up connection to the cloud has been severed (503)
                                </>
                            ),
                            severity: 'error',
                            translate: false,
                        });
                    }
                });
        } else {
            let tenantUserRole = user.tenantUserRoles.find((tur) => tur.tenantRoleId === data.id);

            if (!tenantUserRole) {
                return;
            }

            rightsApi()
                .delete(`v1/rights/tenantuserrole/${tenantUserRole.id}`)
                .then((response) => {
                    let index = user.tenantUserRoles.findIndex((tur) => tur.tenantRoleId === data.id);

                    if (index === -1) {
                        return;
                    }

                    let roles = [...user.tenantUserRoles];
                    roles.splice(index, 1);

                    setUser({ ...user, tenantUserRoles: roles });

                    setSnackbar({
                        open: true,
                        text: (
                            <>
                                {data.name} <Translate id='rights.users.roles.snackbar.successDelete' />
                            </>
                        ),
                        severity: 'warning',
                        translate: false,
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        setSnackbar({
                            open: true,
                            text: (
                                <>
                                    <Translate id='rights.users.roles.snackbar.error' /> {typeof error?.response?.data === 'string' ? error?.response?.data : error.response.status}
                                </>
                            ),
                            severity: 'error',
                            translate: false,
                        });
                    } else {
                        setSnackbar({
                            open: true,
                            text: (
                                <>
                                    <Translate id='rights.users.roles.snackbar.error' /> Your dail-up connection to the cloud has been severed
                                </>
                            ),
                            severity: 'error',
                            translate: false,
                        });
                    }
                });
        }
    };

    const sortRoleBadges = (a, b) => {
        return a.tenantRole.name.localeCompare(b.tenantRole.name);
    };

    /*********************************************************************/
    /* Managetable config
    /*********************************************************************/
    let data = [];

    if (user && tenantRoles) {
        data = tenantRoles.map((tr) => {
            let hasRole = user.tenantUserRoles.findIndex((utr) => utr.tenantRoleId === tr.id) !== -1;

            return {
                ...tr,
                hasRole: hasRole,
            };
        });
    }

    const config = {
        columns: {
            name: {
                label: <Translate id='rights.users.roles.name' />,
                component: RoleDisplay,
                props: {
                    color: (d) => d.color,
                    label: 'name',
                },
                execute: ['color'],
            },
            isActive: {
                label: <Translate id='rights.users.roles.isActive' />,
                component: CheckDisplay,
                props: {
                    checked: 'isActive',
                },
            },
            hasRole: {
                label: <Translate id='rights.users.roles.isAssigned' />,
                component: SwitchWrapper,
                props: {
                    checked: 'hasRole',
                    onChange: () => handleSwitchChanged,
                    data: (data) => data,
                },
                execute: ['onChange', 'data'],
            },
        },
        noItemsComponent: (
            // No entries
            <NoContent
                title={<Translate id='rights.users.roles.noEntries' />}
                description={<Translate id='rights.users.roles.noEntriesDesc' />}
                backgroundColour='bg-primary'
                icon='fas fa-shelves-empty fa-7x'
            />
        ),
        sortBy: {
            by: 'name',
            order: sortOrder.ASC,
        },
        data: data,
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    // Errors
    if (error.user || error.tenantRoles) {
        if (error.user) {
            return <Error code={error.user.statusCode} message={error.user?.data} />;
        } else if (error.tenantRoles) {
            return <Error code={error.tenantRoles.statusCode} message={error.tenantRoles?.data} />;
        }
    }

    // Load user
    if (!user || !tenantRoles) {
        let title = <Translate id='rights.users.roles.loading.all.title' />;
        let subtitle = <Translate id='rights.users.roles.loading.subtitle' />;

        if (user && !tenantRoles) {
            title = <Translate id='rights.users.roles.loading.user.title' />;
        }

        if (!user && tenantRoles) {
            title = <Translate id='rights.users.roles.loading.roles.title' />;
        }

        return <Loading title={title} description={subtitle} />;
    }

    // Display user roles as badge in header
    let userRolesContent = null;
    if (user.tenantUserRoles && user.tenantUserRoles.length > 0) {
        let roles = user.tenantUserRoles.sort(sortRoleBadges).map((utr, index) => {
            let className = [];

            if (index !== 0 && index + 1 !== user.tenantUserRoles.length) {
                // Anything not first or last
                className.push('mx-1');
            } else if (index === 0) {
                // First
                className.push('mr-1');
            } else if (index + 1 === user.tenantUserRoles.length) {
                // Last
                className.push('ml-1');
            }

            return <RoleDisplay key={index} color={utr.tenantRole.color} label={utr.tenantRole.name} className={className.join(' ')} />;
        });

        userRolesContent = <div className='d-flex flex-row mt-1'>{roles}</div>;
    }

    return (
        <>
            <div className='bg-white box-shadow mb-3'>
                <div className='d-flex p-4'>
                    <i className='far fa-user fa-3x' />
                    <div className='d-flex flex-column ml-3'>
                        <div>
                            {/* Change this to the user name when we have an api to fetch the users from the oauth */}
                            {user.user.firstName} {user.user.surName}
                            {/* <div className='text-muted'>{user.id}</div> */}
                            {userRolesContent}
                        </div>
                    </div>
                </div>
            </div>

            <ManageTable {...config} />
        </>
    );
};

export default UserRolesOverview;
