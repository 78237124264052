import React from 'react';
import Tippy from '@tippyjs/react';

const RightsDisplay = ({ rights }) => {
    let icons = [...rights].map((r, index) => {
        let className = ['fal', 'text-primary'];

        className.push(r.icon);

        if (index > 0) {
            className.push('ml-2');
        }

        return (
            <Tippy content={r.description}>
                <i key={index} className={className.join(' ')} />
            </Tippy>
        );
    });
    return <div className='d-flex'>{icons}</div>;
};

export default RightsDisplay;
