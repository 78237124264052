import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Translate } from '@hanssens/ht-translate';
import { TitleBar, NoContent, ManageTable, sortOrder } from 'ht-gui';

import rightsApi from '../../../../api/rightsApi';

import RightsDisplay from './RightsDisplay';
import PartsDisplay from './PartsDisplay';
import Loading from '../../../Shared/Loading';
import Error from '../../../Shared/Error';

const RoleApplicationParts = ({ selectedApplication }) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/

    const { id } = useParams();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    const compareArray = (arr1, arr2) => {
        const set1 = new Set(arr1);
        const set2 = new Set(arr2);
        return arr1.every((item) => set2.has(item)) && arr2.every((item) => set1.has(item));
    };

    const fetchData = useCallback(() => {
        if (selectedApplication) {
            rightsApi()
                .get(`v1/rights/tenantRole/${id}/info/partRights/?applicationId=${selectedApplication.id}`)
                .then((response) => {
                    let responseData = [];

                    response.data.forEach((d) => {
                        // Get all similar rights
                        let rights = [];
                        response.data.forEach((d2) => {
                            if (d2.tenantApplicationPartId === d.tenantApplicationPartId) {
                                if (rights.findIndex((r) => r?.id === d2.applicationRight.id) === -1) {
                                    rights.push(d2.applicationRight);
                                }
                            }
                        });

                        // Check if it already exists in the list
                        let index = responseData.findIndex((rd) => rd.tenantApplicationPartId !== d.tenantApplicationPartId && rd.applicationRightId === d.applicationRightId);
                        if (index !== -1) {
                            let obj = { ...responseData[index] };

                            // Check if the rights are the same
                            if (obj.rights.length === rights.length) {
                                if (
                                    compareArray(
                                        [
                                            ...rights.map((r) => {
                                                return r.id;
                                            }),
                                        ],
                                        [
                                            ...obj.rights.map((r) => {
                                                return r.id;
                                            }),
                                        ]
                                    )
                                ) {
                                    // Update
                                    obj.parts.push(d.tenantApplicationPart.name);
                                }
                            }
                        } else if (responseData.findIndex((rd) => rd.tenantApplicationPartId === d.tenantApplicationPartId) === -1) {
                            // Add
                            responseData.push({
                                tenantApplicationPartId: d.tenantApplicationPartId,
                                applicationRightId: d.applicationRightId,
                                name: d.tenantApplicationPart.applicationPart.name,
                                parts: [d.tenantApplicationPart.name],
                                rights: rights,
                            });
                        }
                    });

                    responseData.forEach((rd) => rd.parts.sort());

                    setData(handleInitialSort(responseData));
                })
                .catch((error) => {
                    if (error.response) {
                        setError({ statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null });
                    } else {
                        setError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                    }
                });
        }
    }, [selectedApplication, id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/

    const handleRightsSort = (a, b, order) => {
        if (a.rights.length === b.rights.length) {
            return 0;
        }

        if (order === sortOrder.DESC) {
            if (a.rights.length > b.rights.length) {
                return 1;
            } else {
                return -1;
            }
        } else {
            if (a.rights.length > b.rights.length) {
                return -1;
            } else {
                return 1;
            }
        }
    };

    const handlePartsSort = (a, b, order) => {
        if (a.parts.length === b.parts.length) {
            return 0;
        }

        if (order === sortOrder.DESC) {
            if (a.parts.length > b.parts.length) {
                return 1;
            } else {
                return -1;
            }
        } else {
            if (a.parts.length > b.parts.length) {
                return -1;
            } else {
                return 1;
            }
        }
    };

    const handleInitialSort = (data) => {
        const newData = [...data];

        newData.sort((a, b) => {
            let compare = b.name.toLowerCase().localeCompare(a.name.toLowerCase());
            if (compare !== 0) return compare;

            if (a.parts.length > b.parts.length) return -1;
            if (a.parts.length < b.parts.length) return 1;
            return 0;
        });

        return newData;
    };

    /*********************************************************************/
    /* Managetable config
    /*********************************************************************/

    const config = {
        columns: {
            name: {
                label: <Translate id='rights.roles.info.partRights.name' />,
            },
            parts: {
                label: <Translate id='rights.roles.info.partRights.parts' />,
                sort: handlePartsSort,
                component: PartsDisplay,
                props: {
                    parts: 'parts',
                },
            },
            rights: {
                label: <Translate id='rights.roles.info.partRights.rights' />,
                sort: handleRightsSort,
                component: RightsDisplay,
                props: {
                    rights: (d) =>
                        d.rights.map((r) => {
                            return { icon: r.icon, description: r.description };
                        }),
                },
                execute: ['rights'],
            },
        },
        noItemsComponent: (
            // No entries
            <NoContent
                title={<Translate id='rights.roles.info.partRights.noEntries' />}
                description={<Translate id='rights.roles.info.partRights.noEntriesDesc' />}
                backgroundColour='bg-primary'
                icon='fas fa-shelves-empty fa-7x'
            />
        ),
        sortBy: {
            by: 'name',
            order: sortOrder.ASC,
            hideInactiveSort: true,
            // onChange: handleSortChanged,
        },
        hideSearch: true,
        data: data,
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    let content = null;

    if (error) {
        content = <Error className='d-flex justify-content-center bg-white p-5' code={error.statusCode} message={error?.data} noWrapper />;
    }

    if (!data) {
        content = (
            <Loading
                className='d-flex justify-content-center bg-white p-5 box-shadow'
                title={<Translate id='rights.roles.info.rights.loading' />}
                description={<Translate id='rights.roles.info.rights.loadingDesc' />}
                backgroundColor='bg-primary'
                noWrapper
            />
        );
    } else {
        content = <ManageTable {...config} />;
    }

    return (
        <div className='bg-white'>
            <div className='box-shadow'>
                <TitleBar label={<Translate id='rights.roles.info.partRights.title' />} />
            </div>
            {content}
        </div>
    );
};

export default RoleApplicationParts;
