import React, { useEffect, useState } from 'react';
import { PageTitle, SaveButton, saveStatuses, saveTypes } from 'ht-gui';
import { getClasses } from 'ht-resize';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'ht-store';

import { Translate } from '@hanssens/ht-translate';
import rightsApi from '../../../api/rightsApi';

import UserGeneralAdmin from './UserCreate/UserGeneralAdmin';
import { verifyObject } from '../../../utilities/verificator';
import { userAdminRules } from './UserCreate/verificationRules';
import { routerPrefix } from '../../../routers/MainRouter';

export const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const UserCreateAdmin = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const navigate = useNavigate();
    const [tenant] = useStore('rights-tenant');

    const [user, setUser] = useState({
        id: null,
        tenantId: tenant.selectedTenant,
        userId: null,
        isActive: true,
        isDefault: false,
    });
    const [status, setStatus] = useState({
        saveStatus: saveStatuses.NEUTRAL,
    });
    const [error, setError] = useState(false);

    /*********************************************************************/
    /* Save & cancel functions
    /*********************************************************************/

    useEffect(() => {
        if (status.saveStatus === saveStatuses.SAVE_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.SAVING,
            });

            // Verify
            if (!verifyObject(user, userAdminRules)) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }

            // Check if we have valid guids
            if (!guidRegex.test(user.userId)) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }

            rightsApi()
                .post('v1/rights/tenantUser', user)
                .then((response) => {
                    navigate(`${routerPrefix}/users`);

                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.SAVED,
                    });
                })
                .catch((error) => {
                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.ERROR,
                    });
                });
        }
    }, [status, navigate, user]);

    useEffect(() => {
        if (status.saveStatus === saveStatuses.CANCEL_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.CANCELING,
            });

            navigate(`${routerPrefix}/users`);
        }
    }, [status, navigate]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/

    const handleSaveStatusChanged = (s) => {
        setStatus({
            ...status,
            saveStatus: s,
        });
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    return (
        <section id='rights-user-create' className='main-scrollable p-4'>
            <PageTitle title={<Translate id='rights.users.create.title' />} subTitle={<Translate id='rights.users.create.subtitle' />} />

            <div className='row'>
                <UserGeneralAdmin user={user} setUser={setUser} status={status} error={error} />

                <div className='col-12'>
                    <div className='bg-white box-shadow p-3 mt-3'>
                        <div className='row'>
                            <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                                <div className='w-50'>
                                    <SaveButton showFeedback type={saveTypes.CONTROLLED} status={status.saveStatus} setStatus={handleSaveStatusChanged} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UserCreateAdmin;
