import React from 'react';
import withResize from 'ht-resize';
import { Route, Routes } from 'react-router-dom';
import { useTranslate } from '@hanssens/ht-translate';

// Translations
import globalTranslations from '../translations/global.json';

// Components
import PrivateTenantRoute from '../components/Routers/PrivateTenantRoute';
import AuthRoute from '../components/Routers/AuthRoute';

import UserRouter from './UserRouter';
import RolesRouter from './RolesRouter';
import ApplicationsRouter from './ApplicationsRouter';

// Css
import '../css/main.scss';
import 'tippy.js/dist/tippy.css';

export const routerPrefix = '/rights';

const MainRouter = (props) => {
    /***********************************************************************
     * Startup
     ***********************************************************************/
    useTranslate(globalTranslations);

    /***********************************************************************
     * Render
     ***********************************************************************/

    // Return the routes
    return (
        <div className='ht-app-rights'>
            <AuthRoute>
                <PrivateTenantRoute>
                    <Routes>
                        {/* Applications */}
                        <Route path='/applications/*' element={<ApplicationsRouter />} />

                        {/* Roles */}
                        <Route path='/roles/*' element={<RolesRouter />} />

                        {/* Users */}
                        <Route path='/users/*' element={<UserRouter />} />

                        {/* Default route */}
                        <Route path='/*' element={<UserRouter />} />
                    </Routes>
                </PrivateTenantRoute>
            </AuthRoute>
        </div>
    );
};

export default withResize()(MainRouter);
