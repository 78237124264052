import React from 'react';
import { PageTitle } from 'ht-gui';

import { Translate } from '@hanssens/ht-translate';

import UserRolesOverview from './UserRoles/UserRolesOverview';

const UserRoles = (props) => {
    return (
        <section id='rights-user-update' className='main-scrollable p-4'>
            <PageTitle title={<Translate id='rights.users.roles.update.title' />} subTitle={<Translate id='rights.users.roles.update.subtitle' />} />

            <UserRolesOverview />
        </section>
    );
};

export default UserRoles;
