import React, { useEffect, useCallback, useState } from 'react';
import { NoContent, PageTitle } from 'ht-gui';
import { useParams } from 'react-router-dom';
import { Translate } from '@hanssens/ht-translate';
import { getClasses } from 'ht-resize';
import { useStore } from 'ht-store';

import rightsApi from '../../api/rightsApi';

// Components
import RoleApplicationsInfo from '../../components/General/Roles/RoleInfo/RoleApplicationsInfo';
import RoleApplications from '../../components/General/Roles/RoleInfo/RoleApplications';
import RoleApplicationParts from '../../components/General/Roles/RoleInfo/RoleApplicationParts';
import Loading from '../../components/Shared/Loading';
import Error from '../../components/Shared/Error';

// Css
import './roleInfo.scss';

const RoleInfo = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const [tenant] = useStore('rights-tenant');
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const [selectedApplication, setSelectedApplication] = useState(null);

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    const fetchData = useCallback(() => {
        rightsApi()
            .get(`v1/rights/tenantRole/${id}/info/applications`)
            .then((response) => {
                setError(null);
                setData(response.data);

                if (response.data && response.data.applications.length > 0) {
                    setSelectedApplication(response.data.applications[0]);
                }
            })
            .catch((error) => {
                if (error.response) {
                    setError({ statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null });
                } else {
                    setError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                }
                setData(null);
            });
    }, [id]);

    useEffect(() => {
        if (tenant && tenant.selectedTenant) {
            fetchData();
        }
    }, [fetchData, tenant]);

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    let content = null;

    if (error) {
        content = <Error code={error.statusCode} message={error?.data} />;
    } else if (!data) {
        content = <Loading title={<Translate id='rights.roles.info.loading' />} description={<Translate id='rights.roles.info.loadingDesc' />} />;
    } else if (data && (!data.applications || data.applications.length === 0)) {
        content = (
            <NoContent
                title={<Translate id='rights.roles.info.noEntries' />}
                description={<Translate id='rights.roles.info.noEntriesDesc' />}
                backgroundColour='bg-primary'
                icon='fas fa-shelves-empty fa-7x'
            />
        );
    } else {
        content = (
            <div className='row'>
                <RoleApplicationsInfo applications={data.applications} setSelectedApplication={setSelectedApplication} selectedApplication={selectedApplication} />
                <div className={getClasses({ md: 'col-8' })}>
                    <RoleApplications selectedApplication={selectedApplication} />
                    <RoleApplicationParts selectedApplication={selectedApplication} />
                </div>
            </div>
        );
    }

    return (
        <section id='rights-role-info' className='main-scrollable p-4'>
            <PageTitle
                title={
                    <>
                        <Translate id='rights.roles.info.title' /> {data ? `- ${data.tenantRole.name}` : null}
                    </>
                }
                subTitle={<Translate id='rights.roles.info.subtitle' />}
            />
            {content}
        </section>
    );
};

export default RoleInfo;
