import React from 'react';
import { getClasses } from 'ht-resize';
import { TitleBar } from 'ht-gui';

import { Translate } from '@hanssens/ht-translate';

const RoleApplicationsInfo = ({ applications, setSelectedApplication, selectedApplication }) => {
    /*********************************************************************/
    /* Function
    /*********************************************************************/
    const handleAppClicked = (e, data) => {
        e.preventDefault();
        setSelectedApplication(data);
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    let applicationsContent = applications.map((app) => {
        let className = ['role-application-item w-100 px-3 py-2 border-bottom'];

        if (selectedApplication && selectedApplication.id === app.id) {
            className.push('highlighted');
        } else {
            className.push('bg-white');
        }

        return (
            <div className={className.join(' ')} onClick={(e) => handleAppClicked(e, app)}>
                {app.name}
            </div>
        );
    }, []);

    return (
        <div className={getClasses({ lg: 'col-4' })}>
            <TitleBar label={<Translate id='rights.roles.info.applications.title' />} />
            <div className='bg-white box-shadow'>{applicationsContent}</div>
        </div>
    );
};

export default RoleApplicationsInfo;
