import React, { useEffect, useState } from 'react';
import { PageTitle } from 'ht-gui';
import { Outlet, useParams } from 'react-router-dom';

import { Translate } from '@hanssens/ht-translate';
import rightsApi from '../../../api/rightsApi';

import Loading from '../../Shared/Loading';
import Error from '../../Shared/Error';
import ButtonBar from './ApplicationCreate/ButtonBar';

const ApplicationEdit = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const { id } = useParams();

    const [application, setApplication] = useState(null);
    const [apiError, setApiError] = useState(null);

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    useEffect(() => {
        rightsApi()
            .get('v1/rights/tenantApplication/' + id)
            .then((response) => {
                setApplication(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    setApiError({
                        statusCode: error.response.status,
                        data: typeof error?.response?.data === 'string' ? error?.response?.data : null,
                    });
                } else {
                    setApiError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                }
            });
    }, [id]);

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    // Loading
    let content = <Loading title={<Translate id='rights.applications.update.loading' />} description={<Translate id='rights.applications.update.loadingDesc' />} />;

    // Error
    if (apiError) {
        content = <Error code={apiError.statusCode} message={apiError?.data} />;
    } else if (application) {
        content = (
            <div className='mt-3 row'>
                <Outlet context={[application, setApplication]} />
            </div>
        );
    }

    return (
        <section id='rights-application-update' className='main-scrollable p-4'>
            <PageTitle title={<Translate id='rights.applications.update.title' />} subTitle={<Translate id='rights.applications.update.subtitle' />} />
            {application ? <ButtonBar /> : null}
            {content}
        </section>
    );
};

export default ApplicationEdit;
