import React, { useState } from 'react';
import { Translate } from '@hanssens/ht-translate';
import { TitleBar, ManageTable, NoContent } from 'ht-gui';
import { useStore } from 'ht-store';
import { useParams } from 'react-router-dom';

import TitleBarToggle from '../../../Shared/TitleBar/TitleBarToggle';
import SwitchWrapper from '../../../Shared/Managetable/SwitchWrapper';
import RightDisplay from '../../../Shared/Managetable/RightDisplay';

const RoleApplicationRights = ({ rights, selectedApplication }) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/

    const { id } = useParams();
    const [toggled, setToggled] = useState(true);
    const [roleRights, setRoleRights] = useStore('rights-roleRights-update');

    /*********************************************************************/
    /* Functions
    /*********************************************************************/
    const updateRoleRights = (checked, data) => {
        if (checked) {
            // Add
            let rr = [...roleRights.tenantApplicationRoleRights];

            if (selectedApplication && selectedApplication.id) {
                rr.push({
                    id: null,
                    tenantApplicationId: selectedApplication.id,
                    applicationRightId: data.id,
                    tenantRoleId: id,
                });
            }

            setRoleRights({ ...roleRights, tenantApplicationRoleRights: rr });
        } else {
            // Remove
            let rr = [...roleRights.tenantApplicationRoleRights];

            let index = rr.findIndex((r) => r.applicationRightId === data.id);

            if (index === -1) {
                return;
            }

            rr.splice(index, 1);

            setRoleRights({ ...roleRights, tenantApplicationRoleRights: rr });
        }
    };

    const handleSwitchChanged = (e, data) => {
        updateRoleRights(e.target.checked, data);
    };

    /*********************************************************************/
    /* Managetable config
    /*********************************************************************/

    let data = [];

    if (rights && selectedApplication && selectedApplication.applicationId) {
        data = rights
            .filter((r) => r.applicationId === selectedApplication.applicationId && r.applicationPartId === null)
            .map((r) => {
                // Check if role exists in roleRights
                let checked = false;
                let disabled = false;
                if (roleRights.tenantApplicationRoleRights) {
                    checked = roleRights.tenantApplicationRoleRights.findIndex((rr) => rr.applicationRightId === r.id) !== -1;
                } else {
                    disabled = true;
                }

                return {
                    ...r,
                    action: {
                        disabled: disabled,
                        checked: checked,
                    },
                };
            });
    }

    const config = {
        columns: {
            displayNameCategory: {
                label: <Translate id='rights.roles.rights.category' />,
            },
            displayName: {
                label: <Translate id='rights.roles.rights.right' />,
                component: RightDisplay,
                props: {
                    name: 'displayName',
                    icon: (d) => d.icon,
                },
                execute: ['icon'],
            },
            description: {
                label: <Translate id='rights.roles.rights.description' />,
            },
            action: {
                label: <Translate id='rights.roles.rights.action' />,
                component: SwitchWrapper,
                props: {
                    checked: (d) => d.action.checked,
                    disabled: (d) => d.action.disabled,
                    data: (d) => d,
                    onChange: () => handleSwitchChanged,
                },
                execute: ['checked', 'disabled', 'data', 'onChange'],
            },
        },
        noItemsComponent: (
            // No entries
            <NoContent
                title={<Translate id='rights.roles.info.rights.noEntries' />}
                description={<Translate id='rights.roles.info.rights.noEntriesDesc' />}
                backgroundColour='bg-primary'
                icon='fas fa-display-slash fa-7x'
            />
        ),
        data: data,
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    let content = null;

    if (toggled) {
        content = <ManageTable {...config} />;
    }

    return (
        <div className='mt-3'>
            <div className='box-shadow'>
                <TitleBar label={<Translate id='rights.roles.rights.applicationRights.title' />} componentsAfter={<TitleBarToggle toggled={toggled} onToggle={(value) => setToggled(value)} />} />
            </div>

            {content}
        </div>
    );
};

export default RoleApplicationRights;
