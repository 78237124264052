import React, { useState, useCallback, useEffect } from 'react';
import { getClasses } from 'ht-resize';
import { SaveButton, Select, saveTypes } from 'ht-gui';
import { useStore } from 'ht-store';

import rightsApi from '../../../../api/rightsApi';
import { Translate } from '@hanssens/ht-translate';

const RoleRightsHeader = ({ selectedApplication, setSelectedApplication, status, setStatus }) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const [tenant] = useStore('rights-tenant');
    const [applications, setApplications] = useState([]);
    const [error, setError] = useState(null);

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    const fetchData = useCallback(() => {
        rightsApi()
            .get(`v1/rights/tenantApplication/?tenantId=${tenant.selectedTenant}`)
            .then((response) => {
                let data = response.data.map((d) => {
                    return {
                        value: d.id,
                        label: d.application.name,

                        tenantApplicationId: d.id,
                        applicationId: d.applicationId,
                    };
                });

                setApplications(data);

                if (response.data && response.data.length > 0) {
                    setSelectedApplication({ id: response.data[0].id, applicationId: response.data[0].applicationId });
                }
            })
            .catch((error) => {
                if (error.response) {
                    setError({ statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null });
                } else {
                    setError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                }
            });
    }, [tenant, setSelectedApplication]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/

    const handleSaveStatusChanged = (s) => {
        setStatus({
            ...status,
            saveStatus: s,
        });
    };

    const handleOnChange = (e) => {
        let app = applications.find((app) => app.tenantApplicationId === e.target.value);

        if (!app) {
            return;
        }

        setSelectedApplication({ id: e.target.value, applicationId: app.applicationId });
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    let value = selectedApplication?.id;

    return (
        <div className='bg-white box-shadow mb-3 p-3'>
            <div className='row'>
                <div className={getClasses({ sm: 'col-12', md: 'col-4' })}>
                    <Select
                        disabled={error || !applications || applications.length === 0}
                        value={value}
                        options={applications}
                        onChange={(e) => handleOnChange(e)}
                        label={<Translate id='rights.roles.rights.application' />}
                        id='rights-tenant-applications'
                    />
                </div>
                <div className={getClasses({ sm: 'col-12', md: 'col-8' })}>
                    <div className='row h-100'>
                        <div className={getClasses({ shared: 'ml-auto', sm: 'col-6', md: 'col-4' })}>
                            <div className='d-flex align-items-center h-100'>
                                <SaveButton showFeedback type={saveTypes.CONTROLLED} status={status.saveStatus} setStatus={handleSaveStatusChanged} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoleRightsHeader;
