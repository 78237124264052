import React, { useState } from 'react';
import { Translate } from '@hanssens/ht-translate';
import { TitleBar, ManageTable, NoContent } from 'ht-gui';
import { useStore } from 'ht-store';
import { useParams } from 'react-router-dom';

import TitleBarToggle from '../../../Shared/TitleBar/TitleBarToggle';
import MultiSelectWrapper from '../../../Shared/Managetable/MultiSelectWrapper';
import SwitchWrapper from '../../../Shared/Managetable/SwitchWrapper';
import RightDisplay from '../../../Shared/Managetable/RightDisplay';

const RoleApplicationPartRights = ({ rights, selectedApplication }) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const { id } = useParams();
    const [toggled, setToggled] = useState(true);
    const [roleRights, setRoleRights] = useStore('rights-roleRights-update');

    /*********************************************************************/
    /* Functions
    /*********************************************************************/

    const updateRoleRights = (checked, data) => {
        if (checked) {
            // Add
            let rr = null;
            if (data.allowAllParts) {
                rr = [...roleRights.tenantApplicationRoleRights];
                if (selectedApplication && selectedApplication.id) {
                    rr.push({
                        id: null,
                        tenantApplicationId: selectedApplication.id,
                        applicationRightId: data.id,
                        tenantRoleId: id,
                    });
                }

                setRoleRights({ ...roleRights, tenantApplicationRoleRights: rr });
            } else {
                rr = [...roleRights.tenantApplicationPartRoleRights];
                if (selectedApplication && selectedApplication.id) {
                    rr.push({
                        id: null,
                        tenantApplicationId: selectedApplication.id,
                        applicationRightId: data.id,
                        tenantApplicationPartId: null,
                        tenantRoleId: id,
                    });
                }

                setRoleRights({ ...roleRights, tenantApplicationPartRoleRights: rr });
            }
        } else {
            // Remove
            if (data.allowAllParts) {
                let rr = [...roleRights.tenantApplicationRoleRights];

                rr = [...rr.filter((part) => part.applicationRightId !== data.id)];

                setRoleRights({ ...roleRights, tenantApplicationRoleRights: rr });
            } else {
                let rr = [...roleRights.tenantApplicationPartRoleRights];

                rr = [...rr.filter((part) => part.applicationRightId !== data.id)];

                setRoleRights({ ...roleRights, tenantApplicationPartRoleRights: rr });
            }
        }
    };

    const handleMultiSelectChanged = (e, data) => {
        let rrParts = [...roleRights.tenantApplicationPartRoleRights];

        // Filter out removed parts
        let filteredRRParts = rrParts.filter((rr) => rr.applicationRightId !== data.id || (rr.applicationRightId === data.id && e.target.value.includes(rr.tenantApplicationPartId)));

        let length = filteredRRParts.filter((frrp) => frrp.applicationRightId === data.id).length;

        if (length !== e.target.value.length) {
            if (length > e.target.value.length) {
                // Should not get here
                console.warn('Something went wrong adding / removing parts');
            } else if (length < e.target.value.length) {
                e.target.value.forEach((v) => {
                    if (filteredRRParts.findIndex((frrp) => frrp.applicationRightId === data.id && frrp.tenantApplicationPartId === v) === -1) {
                        let index = filteredRRParts.findIndex((frrp) => frrp.applicationRightId === data.id && frrp.tenantApplicationPartId === null);

                        if (index !== -1) {
                            // Replace null value from initial
                            let obj = filteredRRParts[index];

                            obj.tenantApplicationId = v;

                            filteredRRParts[index] = obj;
                        } else {
                            // Add
                            filteredRRParts.push({
                                id: null,
                                tenantApplicationPartId: v,
                                applicationRightId: data.id,
                                tenantRoleId: id,
                            });
                        }
                    }
                });
            }
        }

        setRoleRights({ ...roleRights, tenantApplicationPartRoleRights: filteredRRParts });
    };

    const handleSwitchChanged = (e, data) => {
        updateRoleRights(e.target.checked, data);
    };

    /*********************************************************************/
    /* Managetable config
    /*********************************************************************/

    let data = [];

    if (rights && selectedApplication && selectedApplication.applicationId && roleRights && roleRights.tenantApplicationPartRoleRights) {
        let filteredRights = rights.filter((r) => r.applicationId === selectedApplication.applicationId && r.applicationPartId !== null);

        data = filteredRights.map((r) => {
            let switchDisabled = !roleRights.tenantApplicationRoleRights;
            let value = [];
            let options = [];
            let checked = false;

            if (r.applicationPart && r.applicationPart.tenantApplicationParts && r.applicationPart.tenantApplicationParts.length > 0) {
                options = r.applicationPart.tenantApplicationParts.map((part) => {
                    return {
                        value: part.id,
                        label: part.name.trim() || part.externalId,
                    };
                });
            }

            if (r.allowAllParts) {
                checked = roleRights.tenantApplicationRoleRights.some((tarr) => {
                    return tarr.applicationRightId === r.id;
                });
            } else {
                roleRights.tenantApplicationPartRoleRights
                    .filter((taprr) => taprr.applicationRightId === r.id)
                    .forEach((taprr) => {
                        checked = true;
                        if (taprr.tenantApplicationPartId) {
                            value.push(taprr.tenantApplicationPartId);
                        }
                    });
            }

            return {
                ...r,
                appPart: r.applicationPart.name,
                select: {
                    value: value,
                    options: options,
                    disabled: !(roleRights.tenantApplicationRoleRights && checked),
                },
                action: {
                    checked: checked,
                    disabled: switchDisabled,
                },
            };
        });
    }

    const config = {
        columns: {
            appPart: {
                label: <Translate id='rights.roles.rights.name' />,
            },
            displayNameCategory: {
                label: <Translate id='rights.roles.rights.category' />,
            },
            displayName: {
                label: <Translate id='rights.roles.rights.right' />,
                component: RightDisplay,
                props: {
                    name: 'displayName',
                    icon: (d) => d.icon,
                },
                execute: ['icon'],
            },
            description: {
                label: <Translate id='rights.roles.rights.description' />,
            },
            select: {
                label: <Translate id='rights.roles.rights.select' />,
                component: MultiSelectWrapper,
                props: {
                    value: (d) => d.select.value,
                    options: (d) => d.select.options,
                    onChange: () => handleMultiSelectChanged,
                    disabled: (d) => d.select.disabled,
                    data: (d) => d,
                    allowAll: (d) => d.allowAllParts,
                },
                execute: ['value', 'options', 'onChange', 'disabled', 'data', 'allowAll'],
            },
            action: {
                label: <Translate id='rights.roles.rights.action' />,
                component: SwitchWrapper,
                props: {
                    checked: (d) => d.action.checked,
                    disabled: (d) => d.action.disabled,
                    data: (d) => d,
                    onChange: () => handleSwitchChanged,
                },
                execute: ['checked', 'disabled', 'data', 'onChange'],
            },
        },
        noItemsComponent: (
            // No entries
            <NoContent
                title={<Translate id='rights.roles.info.partRights.noEntries' />}
                description={<Translate id='rights.roles.info.partRights.noEntriesDesc' />}
                backgroundColour='bg-primary'
                icon='fas fa-display-slash fa-7x'
            />
        ),
        data: data,
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    let content = null;

    if (toggled) {
        content = <ManageTable {...config} />;
    }

    return (
        <div className='mt-5'>
            <div className='box-shadow'>
                <TitleBar label={<Translate id='rights.roles.rights.applicationParts.title' />} componentsAfter={<TitleBarToggle toggled={toggled} onToggle={(value) => setToggled(value)} />} />
            </div>

            {content}
        </div>
    );
};

export default RoleApplicationPartRights;
