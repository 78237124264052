import React from 'react';

const RightDisplay = ({ name, icon }) => {
    let classNames = ['fal', 'text-primary', 'mr-2'];

    if (icon) {
        classNames.push(icon);
    } else {
        classNames.push('fa-question');
    }

    return (
        <div className=''>
            <i className={classNames.join(' ')} />
            {name}
        </div>
    );
};

export default RightDisplay;
