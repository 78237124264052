import React from 'react';
import { Translate, useTranslate } from '@hanssens/ht-translate';
import { routerPrefix } from '../../../routers/MainRouter';

import sidebarLeftTranslations from '../../../translations/layout/sidebarLeft.json';

const SidebarLeft = ({ first, container, onClick }) => {
    // Add translation
    useTranslate(sidebarLeftTranslations);

    /********************************/
    /* Render
    /********************************/
    // Define config
    let config = [
        {
            link: `${routerPrefix}/users`,
            label: <Translate id='rights.sidebarLeft.users' />,
            icon: 'fal fa-users',
            hoverIcon: 'fas fa-users',
        },
        {
            link: `${routerPrefix}/roles`,
            label: <Translate id='rights.sidebarLeft.roles' />,
            icon: 'fal fa-user-unlock',
            hoverIcon: 'fas fa-user-unlock',
        },
        {
            link: `${routerPrefix}/applications`,
            label: <Translate id='rights.sidebarLeft.applications' />,
            icon: 'fal fa-display-code',
            hoverIcon: 'fas fa-display-code',
        },
    ];

    let SidebarItemsContainer = container;
    return <SidebarItemsContainer first={first} config={config} onClick={onClick} title={<Translate id='rights.sidebarLeft.title' />} />;
};

export default SidebarLeft;
