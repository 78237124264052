import React from 'react';
import { PageTitle } from 'ht-gui';

import { Translate } from '@hanssens/ht-translate';

import RolesOverview from '../components/General/Roles/RolesOverview';

const Roles = (props) => {
    return (
        <section id='rights-roles-overview' className='main-scrollable p-4'>
            <PageTitle title={<Translate id='rights.roles.title' />} subTitle={<Translate id='rights.roles.subtitle' />} />
            <div className=''>
                <RolesOverview />
            </div>
        </section>
    );
};

export default Roles;
