import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Translate, useTranslate } from '@hanssens/ht-translate';

import userManager from '../../../../../configs/userManager';

import profileTranslations from '../../../../translations/layout/profile.json';

const Profile = () => {
    /***********************************************************************
     * State
    /***********************************************************************/
    const currentUser = useSelector((state) => state.oidc.user);

    useTranslate(profileTranslations);

    /***********************************************************************
     * Event handlers
    /***********************************************************************/
    const handleLogoutClicked = useCallback(() => {
        userManager.signoutRedirect();
        userManager.removeUser();
    }, []);

    /***********************************************************************
     * Render
    /***********************************************************************/
    return (
        <div className='d-flex flex-column w-100 h-100'>
            {/* Title */}
            <div className='title-row profile row mx-0'>
                <div className='col-3 px-0 mx-0 d-flex align-items-center'>
                    <i className='mx-auto fas fa-user-circle fa-2x text-white'></i>
                </div>
                <div className='col-6 px-0 py-0 mx-0 d-flex align-items-center text-center text-white'>
                    <span className='title-text'>
                        <Translate id='profile.title' />
                    </span>
                </div>
            </div>

            {/* Icon */}
            <div className='row mt-3 mx-0 py-0'>
                <div className='col-12 profile-icon d-flex align-items-center justify-content-center'>
                    <i className='default-profile-icon fas fa-user-circle text-dark'></i>
                </div>
            </div>

            {/* Information */}
            <div className='row mt-2 mx-0 py-0 text-center'>
                <div className='col-12 text-center'>
                    <h6 className='my-0'>
                        {currentUser?.profile?.given_name} {currentUser?.profile?.family_name}
                    </h6>
                </div>
            </div>

            <div className='row mt-auto mb-3 mx-0 py-0'>
                <div className='col-6'></div>
                <div className='col-6'>
                    <button className='btn btn-danger text-white' onClick={handleLogoutClicked}>
                        <Translate id='profile.logout' />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Profile;
