import React, { useState } from 'react';
import Tippy from '@tippyjs/react';

const ManageTableActionWithTippy = ({ icon, hoverIcon, onClick, shouldDisplay, object, disabled, tippyText }) => {
    const [hovered, setHovered] = useState(false);
    let classNames = ['mr-3', 'ht-manage-table-icon-size'];

    /**************************************************
     * Event handlers
     **************************************************/
    const handleMouseEntered = () => {
        setHovered(true);
    };

    const handleMouseLeft = () => {
        setHovered(false);
    };

    const handleIconClicked = () => {
        if (!onClick) {
            console.error('[HT GUI ManageTableAction] Please provide the onClickHandler.');
            return;
        }

        if (!disabled) {
            onClick(object);
        }
    };

    /**************************************************
     * Render
     **************************************************/
    // Determine wether or not the action should be displayed
    if (shouldDisplay !== null && shouldDisplay !== undefined) {
        if (!shouldDisplay(object)) {
            return null;
        }
    }

    // Determine wether or not it's clickable
    if (!disabled) {
        classNames.push('clickable');
    } else {
        classNames.push('unclickable');
    }

    // Add the specific class names for the icons
    if (hovered && hoverIcon && !disabled) {
        classNames.push(hoverIcon);
    } else {
        classNames.push(icon);
    }

    const action = <i className={classNames.join(' ')} onClick={handleIconClicked} onMouseEnter={handleMouseEntered} onMouseLeave={handleMouseLeft} />;

    if (tippyText) {
        return <Tippy content={tippyText}>{action}</Tippy>;
    } else {
        return action;
    }
};

export default ManageTableActionWithTippy;
