import * as React from 'react';
import { Input, TitleBar, saveStatuses } from 'ht-gui';
import { Translate } from '@hanssens/ht-translate';
import { getClasses } from 'ht-resize';

import { checkIfValueIsValidURI, checkIfValueIsValidIPv4WithPort } from '../../../../utilities/regex';

const ApplicationSettings = ({ application, setApplication, status, error, className }) => {
    /*********************************************************************/
    /* Event handlers
    /*********************************************************************/
    const handleApiChanged = (e) => {
        setApplication({ ...application, apiBaseURI: e.target.value });
    };

    const handleSettingChanged = (settingId, value) => {
        let tenantApplicationSettings = [...application.tenantApplicationSettings];

        let index = tenantApplicationSettings.findIndex((tas) => tas.applicationSetting.id === settingId);

        if (index === -1) {
            return;
        }

        tenantApplicationSettings[index] = {
            ...tenantApplicationSettings[index],
            value: value,
        };

        setApplication({ ...application, tenantApplicationSettings: tenantApplicationSettings });
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    let classNames = ['col-12'];

    if (Array.isArray(className)) {
        classNames = [...classNames, ...className];
    } else if (className) {
        classNames = [...classNames, className];
    }

    let dynamicSettingsContent = null;
    if (application.tenantApplicationSettings && application.tenantApplicationSettings.length > 0) {
        let variableContent = application.tenantApplicationSettings.map((tas, index) => {
            return (
                <Input
                    key={tas.applicationSetting.id}
                    value={tas.value}
                    onChange={(e) => handleSettingChanged(tas.applicationSetting.id, e.target.value)}
                    label={<Translate id={`rights.applications.settings.${tas.applicationSetting.name}`} />}
                    id={`rights.applications.settings.${tas.applicationSetting.name}`}
                    required={tas.applicationSetting.required}
                    error={tas.applicationSetting.required && error && !tas.value}
                    disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                    className={index > 0 ? 'mt-3 w-100' : ''}
                />
            );
        });

        dynamicSettingsContent = (
            <div className='row'>
                <div className={getClasses({ shared: 'mt-3', sm: 'col-12', lg: 'col-6' })}>{variableContent}</div>
            </div>
        );
    }

    return (
        <div className={classNames.join(' ')}>
            <div className='bg-white box-shadow'>
                <TitleBar label={<Translate id='rights.applications.create.settings' />} />
                <div className='p-3'>
                    <div className='row'>
                        <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                            <Input
                                value={application.apiBaseURI}
                                onChange={handleApiChanged}
                                label={<Translate id='rights.applications.applicationBaseURI' />}
                                id='rights.application.create.applicationBaseURI'
                                error={error && !(checkIfValueIsValidURI(application.apiBaseURI.trim()) || checkIfValueIsValidIPv4WithPort(application.apiBaseURI.trim()))}
                                errorMessage={<Translate id='rights.applications.create.invalidBaseURI' />}
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                        </div>
                    </div>
                    {dynamicSettingsContent}

                    {/* Error */}
                    {/* {error && !verifyObject(application, applicationRules) ? (
                    <div className='row'>
                        <div className='col-6 '>
                            <div className='alert alert-danger rounded-0 mb-0 mt-3'>
                                <Translate id='rights.applications.create.error' />
                            </div>
                        </div>
                    </div>
                ) : null} */}
                </div>
            </div>
        </div>
    );
};

export default ApplicationSettings;
