import * as React from 'react';
import { Input, SaveButton, saveStatuses, saveTypes } from 'ht-gui';
import { getClasses } from 'ht-resize';
import { Translate } from '@hanssens/ht-translate';

import rightsApi from '../../../../api/rightsApi';

import { verifyObject, verifyProperty } from '../../../../utilities/verificator';
import { partRules } from './verificationRules';

const ApplicationEditPartCreate = ({ tenantApplicationId, partId, setCreating, onPartAdded }) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const [error, setError] = React.useState(false);
    const [status, setStatus] = React.useState({
        saveStatus: saveStatuses.NEUTRAL,
    });
    const [part, setPart] = React.useState({
        id: null,
        tenantApplicationId: tenantApplicationId,
        applicationPartId: partId,
        name: '',
        externalId: null,
    });

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    React.useEffect(() => {
        setPart((p) => {
            return {
                ...p,
                tenantApplicationId: tenantApplicationId,
            };
        });
    }, [tenantApplicationId]);

    React.useEffect(() => {
        setPart((p) => {
            return {
                ...p,
                applicationPartId: partId,
            };
        });
    }, [partId]);

    /*********************************************************************/
    /* Save & cancel functions
    /*********************************************************************/

    React.useEffect(() => {
        if (status.saveStatus === saveStatuses.SAVE_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.SAVING,
            });

            // Verify
            if (!verifyObject(part, partRules)) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }

            rightsApi()
                .post('v1/rights/tenantApplicationPart', part)
                .then((response) => {
                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.SAVED,
                    });
                    onPartAdded(response.data);
                    setCreating(false);
                })
                .catch((error) => {
                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.ERROR,
                    });
                });
        }
    }, [status, setCreating, part, onPartAdded]);

    React.useEffect(() => {
        if (status.saveStatus === saveStatuses.CANCEL_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.CANCELING,
            });

            setCreating(false);
        }
    }, [status, setCreating]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/
    const handleSaveStatusChanged = (s) => {
        setStatus({
            ...status,
            saveStatus: s,
        });
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    return (
        <div className='bg-white box-shadow p-3'>
            <div className='row'>
                <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                    <Input
                        id='rights.part.create.id'
                        value={part.externalId}
                        label={<Translate id='rights.applications.part.id' />}
                        error={error && !verifyProperty(part.externalId, partRules.externalId)}
                        required
                        onChange={(e) => setPart({ ...part, externalId: e.target.value })}
                        className='w-100 mb-3'
                        disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                    />
                    <Input
                        id='rights.role.create.name'
                        value={part.name}
                        label={<Translate id='rights.applications.part.name' />}
                        error={error && !verifyProperty(part.name, partRules.name)}
                        required
                        onChange={(e) => setPart({ ...part, name: e.target.value })}
                        className='w-100 mb-3'
                        disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                    />
                </div>
            </div>
            <div className='row'>
                <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                    <div className='w-50'>
                        <SaveButton showFeedback type={saveTypes.CONTROLLED} status={status.saveStatus} setStatus={handleSaveStatusChanged} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplicationEditPartCreate;
