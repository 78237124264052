import React from 'react';

const PartsDisplay = ({ parts }) => {
    let icons = [...parts].map((part, index) => {
        let className = ['badge', 'bg-dark', 'text-white'];

        if (index > 0) {
            className.push('ml-2');
        }

        return (
            <div key={index} className={className.join(' ')}>
                {part}
            </div>
        );
    });
    return <div className='d-flex'>{icons}</div>;
};

export default PartsDisplay;
