import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Translate } from '@hanssens/ht-translate';
import { TitleBar, NoContent, ManageTable, sortOrder } from 'ht-gui';

import rightsApi from '../../../../api/rightsApi';

import RightsDisplay from './RightsDisplay';
import Loading from '../../../Shared/Loading';
import Error from '../../../Shared/Error';

const RoleApplications = ({ selectedApplication }) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/

    const { id } = useParams();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    /*********************************************************************/
    /* Init
    /*********************************************************************/

    const fetchData = useCallback(() => {
        if (selectedApplication) {
            rightsApi()
                .get(`v1/rights/tenantRole/${id}/info/rights/?applicationId=${selectedApplication.id}`)
                .then((response) => {
                    let responseData = [];

                    response.data.forEach((d) => {
                        let index = responseData.findIndex((rd) => rd.displayNameCategory === d.displayNameCategory);

                        if (index === -1) {
                            // Create new
                            responseData.push({
                                displayNameCategory: d.displayNameCategory,
                                rights: [{ icon: d.icon, description: d.description }],
                            });
                        } else {
                            // Add
                            let obj = { ...responseData[index] };

                            if (obj.rights.findIndex((r) => r === d.icon) === -1) {
                                // Add not already added icon
                                obj.rights.push({ icon: d.icon, description: d.description });
                            }
                        }
                    });

                    setData(handleInitialSort(responseData));
                })
                .catch((error) => {
                    if (error.response) {
                        setError({ statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null });
                    } else {
                        setError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                    }
                });
        }
    }, [selectedApplication, id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/

    const handleRightsSort = (a, b, order) => {
        if (a.rights.length === b.rights.length) {
            return 0;
        }

        if (order === sortOrder.DESC) {
            if (a.rights.length > b.rights.length) {
                return 1;
            } else {
                return -1;
            }
        } else {
            if (a.rights.length > b.rights.length) {
                return -1;
            } else {
                return 1;
            }
        }
    };

    const handleInitialSort = (data) => {
        const newData = [...data];

        newData.sort((a, b) => {
            let compare = b.displayNameCategory.toLowerCase().localeCompare(a.displayNameCategory.toLowerCase());
            if (compare !== 0) return compare;

            if (a.rights.length > b.rights.length) return -1;
            if (a.rights.length < b.rights.length) return 1;
            return 0;
        });

        return newData;
    };

    /*********************************************************************/
    /* Managetable config
    /*********************************************************************/

    const config = {
        columns: {
            displayNameCategory: {
                label: <Translate id='rights.roles.info.rights.name' />,
            },
            rights: {
                label: <Translate id='rights.roles.info.rights.rights' />,
                sort: handleRightsSort,
                component: RightsDisplay,
                props: {
                    rights: (d) => [...d.rights].reverse(),
                },
                execute: ['rights'],
            },
        },
        noItemsComponent: (
            // No entries
            <NoContent
                title={<Translate id='rights.roles.info.rights.noEntries' />}
                description={<Translate id='rights.roles.info.rights.noEntriesDesc' />}
                backgroundColour='bg-primary'
                icon='fas fa-shelves-empty fa-7x'
            />
        ),
        sortBy: {
            by: 'displayNameCategory',
            order: sortOrder.ASC,
            hideInactiveSort: true,
        },
        hideSearch: true,
        data: data,
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    let content = null;

    if (error) {
        content = (
            <div className='d-flex justify-content-center bg-white p-5 mb-3'>
                <Error code={error.statusCode} message={error?.data} noWrapper />
            </div>
        );
    }

    if (!data) {
        content = (
            <div className='d-flex justify-content-center bg-white box-shadow p-5 mb-3'>
                <Loading title={<Translate id='rights.roles.info.rights.loading' />} description={<Translate id='rights.roles.info.rights.loadingDesc' />} backgroundColor='bg-primary' noWrapper />
            </div>
        );
    } else {
        content = <ManageTable {...config} />;
    }

    return (
        <div className='bg-white'>
            <div className='box-shadow'>
                <TitleBar label={<Translate id='rights.roles.info.rights.title' />} />
            </div>
            {content}
        </div>
    );
};

export default RoleApplications;
