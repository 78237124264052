export const computeTextColor = (color) => {
    let rgb = hexToRgb(color);

    // Counting the perceptive luminance - human eye favors green color...
    let luminance = (0.299 * rgb.R + 0.587 * rgb.G + 0.114 * rgb.B) / 255;

    return luminance > 0.5 ? '#000000' : '#ffffff';
};

const hexToRgb = (hex) => {
    // Check if hex string starts with '#'
    if (!hex.trim().startsWith('#')) {
        hex = `#${hex.trim()}`;
    }

    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              R: parseInt(result[1], 16),
              G: parseInt(result[2], 16),
              B: parseInt(result[3], 16),
          }
        : null;
};
