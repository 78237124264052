import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { useTranslate } from '@hanssens/ht-translate';

// Components
import Users from '../pages/Users';
// import UserCreateAdmin from '../components/General/Users/UserCreateAdmin';
import UserCreate from '../components/General/Users/UserCreate';
import UserEdit from '../components/General/Users/UserEdit';
import UserRoles from '../components/General/Users/UserRoles';

// Translations
import userTranslations from '../translations/general/users.json';

const UserRouter = (props) => {
    useTranslate(userTranslations);

    return (
        <Routes>
            {/* Roles */}
            <Route path='/roles/:id' element={<UserRoles />} />

            {/* Update */}
            <Route path='/update/:id' element={<UserEdit />} />

            {/* Create */}
            {/* <Route path='/create' element={<UserCreateAdmin />} /> */}
            <Route path='/create' element={<UserCreate />} />

            {/* Overview */}
            <Route path='/' element={<Users />} />

            {/* Redirect */}
            {/* <Route path='*' element={<Navigate to={routerPrefix + '/users'} replace />} /> */}
        </Routes>
    );
};

export default UserRouter;
