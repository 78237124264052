import * as React from 'react';
import { Input, TitleBar, saveStatuses, Switch } from 'ht-gui';
import { getClasses } from 'ht-resize';

import { Translate } from '@hanssens/ht-translate';
import { verifyProperty, verifyObject } from '../../../../utilities/utility';

import { userRules } from '../UserCreate/verificationRules';

import Property from '../../../Shared/Property';

const UserGeneral = (props) => {
    const { user, setUser, status, error } = props;
    /*********************************************************************/
    /* Render
    /*********************************************************************/

    return (
        <div className='col-12'>
            <div className='bg-white box-shadow'>
                <TitleBar label={<Translate id='rights.users.create.general' />} />
                <div className='p-3'>
                    <div className='row'>
                        <div className={getClasses({ sm: 'col-12', lg: 'col-6' })}>
                            <Input
                                id='rights.user.create.firstName'
                                value={user.user.firstName}
                                label={<Translate id='rights.users.firstName' />}
                                error={error && !verifyProperty(user.user.firstName, userRules.firstName)}
                                required
                                onChange={(e) => setUser({ ...user, user: { ...user.user, firstName: e.target.value } })}
                                className='w-100 mb-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <Input
                                id='rights.user.create.surName'
                                value={user.user.surName}
                                label={<Translate id='rights.users.surName' />}
                                error={error && !verifyProperty(user.user.surName, userRules.surName)}
                                required
                                onChange={(e) => setUser({ ...user, user: { ...user.user, surName: e.target.value } })}
                                className='w-100 mb-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <Property label={<Translate id='rights.users.email' />} value={user.user.email} />

                            <Switch
                                label={<Translate id='rights.users.isActive' />}
                                id='rights.user.create.isActive'
                                checked={user.isActive}
                                onChange={(e) => setUser({ ...user, isActive: e.target.checked })}
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                        </div>
                    </div>

                    {/* Error */}
                    {error && !verifyObject(user, userRules) ? (
                        <div className='row'>
                            <div className='col-6 '>
                                <div className='alert alert-danger rounded-0 mb-0 mt-3'>
                                    <Translate id='rights.users.create.error' />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default UserGeneral;
